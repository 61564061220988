import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BROWSER, subscribeURL } from './constants/common.constants';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';

const COMIC_API = `${environment.API_END_POINT}`;

@Injectable({
  providedIn: "root",
})
export class NewComicService {
  directoryCache: Object = {};
  sidebarItemSelected$: Subject<any> = new Subject<any>();
  friendRequestSelectedTab$: Subject<any> = new Subject<any>();
  friendReqSentRecord: { reqData: string; count: number; };
  private userData = new BehaviorSubject<any>(null);
  userData$ = this.userData.asObservable();

  private refreshLoginData = new Subject<any>();

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private afAuth: AngularFireAuth
  ) { }

  sendRefreshLoginDataEvent() {
    this.refreshLoginData.next();
  }

  getRefreshLoginDataEvent() {
    return this.refreshLoginData.asObservable();
  }

  setUserData(data: any) {
    this.userData.next(data);
  }

  getUserData(): any {
    return this.userData.getValue();
  }

  setsidebarItemSelected(data: any) {
    this.sidebarItemSelected$.next(data);
  }

  getsidebarItemSelected() {
    return this.sidebarItemSelected$.asObservable(); // following || home
  }

  setFriendRequestSelectedTab(data: string) {
    this.friendRequestSelectedTab$.next(data);
  }

  getFriendRequestSelectedTab() {
    return this.friendRequestSelectedTab$.asObservable();
  }

  public getCurrentUrl(): string {
    return this.router.url.split("?")[0];
  }

  // for home and following page, as these both pages have same URL
  public isHomePage(): boolean {
    return ["", "/"].includes(this.getCurrentUrl());
  }

  // for Main Dashboard
  public isMainDashboard(): boolean {
    return this.getCurrentUrl() === "/dashboard";
  }

  // for Totals Dashboard
  public isTotalsDashboard(): boolean {
    return this.getCurrentUrl() === "/dashboard/totals";
  }

  // for Series Dashboard
  public isCreatorDashboard(): boolean {
    return this.getCurrentUrl() === "/dashboard/creators";
  }

  // for Referal Dashboard
  public isReferralDashboard(): boolean {
    return this.getCurrentUrl() === "/dashboard/referrals";
  }

  // for Top Referrer Dashboard
  public isTopReferrer(): boolean {
    return this.getCurrentUrl() === "/dashboard/top-referrer";
  }

  public isEditProfilePage(): boolean {
    return ["/edit-profile"].includes(this.getCurrentUrl());
  }

  public isDirectoryPage(): boolean {
    return ["/tinyview/comic-series-directory"].includes(this.getCurrentUrl());
  }

  public isInfluencePointsPage(): boolean {
    return ["/tinyview/influence-points"].includes(this.getCurrentUrl());
  }

  public isTnCPage(): boolean {
    return ["/terms-conditions"].includes(this.getCurrentUrl());
  }

  public isPrivacyPolicyPage(): boolean {
    return ["/privacy-policy"].includes(this.getCurrentUrl());
  }

  public isNotificationPage(): boolean {
    return ["/notifications"].includes(this.getCurrentUrl());
  }

  public isManageAlertsPage(): boolean {
    return this.getCurrentUrl().indexOf('manage-alerts') > -1;
  }

  public isSignUpNewPage(): boolean {
    const url = this.getCurrentUrl();
    const signupPattern = /^\/signup\/[^\/]+$/;
    return signupPattern.test(url);
  }

  public isSignInPage(): boolean {
    const url = this.getCurrentUrl();
    const signinPattern = /^\/signin\/[^\/]+$/;
    return signinPattern.test(url);
  }

  public isTermsAndConditions(): boolean {
    return ["/terms-conditions"].includes(this.getCurrentUrl());
  }

  public isPrivacyPolicy(): boolean {
    return ["/privacy-policy"].includes(this.getCurrentUrl());
  }

  public isSeeFriends(): boolean {
    return ["/see-friends"].includes(this.getCurrentUrl());
  }

  public isFriendRequest(): boolean {
    return ["/friend-request"].includes(this.getCurrentUrl());
  }

  public isSubscribePage(): boolean {
    return ["/tinyview/subscribe"].includes(this.getCurrentUrl());
  }

  public isNewUserPage(): boolean {
    return ["/tinyview/new-user"].includes(this.getCurrentUrl());
  }

  // Dupe of comic service
  public isComicPage(): boolean {
    // SSR check
    if (typeof window === "undefined") {
      return false;
    }
    // i.e path - "/heart-and-brain/2022/01/26/sleepless"
    const path = location.pathname;
    if (!['/', '/tinyview/comic-series-directory'].includes(path) && !path.includes('/edit')
      && !this.isStoryPage() && path.split('/').length > 2) {
      return true;
    }
    return false
  }

  public isStoryPage(): boolean {
    const regexPattern = /\/story\/[a-zA-Z0-9]+/g;
    return regexPattern.test(this.getCurrentUrl());
    // return !this.isComicPage() && (this.getCurrentUrl().indexOf('story') > -1);
  }

  public isExternalImage(imageURL: string): boolean {
    return imageURL.startsWith('http');
  }

  public isMobileView(): boolean {
    // SSR check
    if (typeof window === "undefined") {
      return false;
    }
    if (window.innerWidth <= 991) {
      return true;
    }
    return false;
  }

  public getOperatingSystem(): string {
    let currentDevice;
    if (isPlatformBrowser(this.platform) && navigator) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        currentDevice = BROWSER.ANDROID;
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        currentDevice = BROWSER.IOS;
        // } else if (/windows/i.test(userAgent)) {
        //   currentDevice = BROWSER.WINDOWS;
      } else {
        currentDevice = BROWSER.DESKTOP
      }
    } else {
      currentDevice = BROWSER.DESKTOP;
    }
    return currentDevice;
  }

  public isDesktop(): boolean {
    return this.getOperatingSystem() === 'desktopBrowser';
  }

  public isAndroid(): boolean {
    return this.getOperatingSystem() === 'androidBrowser';
  }

  public isIOS(): boolean {
    return this.getOperatingSystem() === 'iosBrowser';
  }

  public isWindows(): boolean {
    if (isPlatformBrowser(this.platform) && navigator) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/windows/i.test(userAgent)) {
        return true;
      }
    }
    return false;
  }

  public async sendFriendRequest(inviteFriendsData) {
    return new Promise(async (resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (!user || !user.uid) {
        return;
      }
      const inviteFriendsData: any = JSON.parse(localStorage.getItem('invite_friends_data') || '{}');
      if (user && ['FRIEND_REQUEST', 'INVITE_FRIEND'].includes(inviteFriendsData.actionType)) {
        if (user.uid === inviteFriendsData.senderUID ) {
          localStorage.removeItem('invite_friends_data');
          localStorage.setItem('invite_friends_flow_completed', 'true');
          return;
        }
        // User is authenticated, we can access user information here
        const reqData = {
          requests: [
            {
              status: "pending",
              receiverUID: user.uid // loggedin user UID
            }
          ],
          senderDetails: {
            senderProfilePic: inviteFriendsData.senderProfilePic,
            senderPhoneNumber: inviteFriendsData.senderNumber,
            senderName: inviteFriendsData.senderName,
            senderUID: inviteFriendsData.senderUID
          }
        };

        // handling for prevention of multiple req sent for same payload at a time
        if (this.friendReqSentRecord && (this.friendReqSentRecord.reqData) === JSON.stringify(reqData)) {
          console.log('prevented same req sent again, ', reqData);
          return;
        }
        const sendFriendRequestRef = firebase.functions().httpsCallable('sendFriendRequest');
        try {
          const res = await sendFriendRequestRef(reqData);
          if (res.data.success) {
            localStorage.removeItem('invite_friends_data');
            localStorage.setItem('invite_friends_flow_completed', 'true');
            this.friendReqSentRecord = {
              reqData: JSON.stringify(reqData || {}),
              count: 1
            };
          }

          resolve(res.data); // Resolve the Promise with the response data
        } catch (error) {
          console.log(error);
          reject(error); // Reject the Promise if there's an error
        }
      }
    })
  }
}
