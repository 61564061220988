import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { NewComicService } from "src/app/new-comic.service";
import { ComicService } from "src/app/services/comic.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PanelService } from "src/app/services/panel/panel.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
const COMIC_API = `${environment.API_END_POINT}`

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {

  @Input('listItemData') comicData: any;
  isMobile: boolean;
  dot: ' • ';
  listItem = {
    title: '',
    time: '',
    views: '',
    image: '',
    firstComic: false
  };
  currentUrl: string;
  checkActionType: boolean;
  nextRoute: string;
  query: string;
  routePath: string;
  comicAction: string;
  viewIcon = '../../../assets/images/eye-100.png';
  isPremium: boolean;
  srcPremium: string;

  constructor(
    private readonly newComicService: NewComicService,
    private readonly comicService: ComicService,
    private readonly panelService: PanelService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,

  ) {
    this.isMobile = this.newComicService.isMobileView();
    this.currentUrl = this.comicService.getCurrentUrl();

  }

  ngOnInit() {
    this.checkActionType = this.checkAction(this.comicData);
    this.nextRoute = this.getNavigateUrl();
    if (this.nextRoute && this.nextRoute.indexOf('fn=') != -1) {
      this.query = this.nextRoute.slice(this.nextRoute.indexOf('fn=') + 3, this.nextRoute.length);
      this.routePath = this.nextRoute.slice(0, this.nextRoute.indexOf('?'));
    }
    this.prepareViewData();
  }
  prepareViewData() {
    if (!this.comicData) return;
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.isPremium = this.comicData['show-to'] && this.comicData['show-to']['key'] === 'subscribers-only';
    if (this.isPremium && this.comicData['show-to']['icon']) this.srcPremium = `${COMIC_API + this.comicData['show-to']['icon']}`
    this.listItem.time = this.comicService.formatDate(this.comicData.createdAt).split('•')[0].trim();
    this.listItem.title = this.comicData.title,
      this.listItem.views = this.comicData.views,
      this.listItem.image = this.comicData.image,
      this.listItem.firstComic = this.comicData.firstComic ? true : false
  }

  public getImgUrl(image: string) {
    return this.panelService.getImgUrl(image, this.currentUrl);
  }

  private checkAction(comicData) {
    if (comicData.action == 'download-app-android' || comicData.action == 'download-app-ios' || comicData.action == 'share' || comicData.action == 'restore-purchases') {
      return true;
    } else {
      return false;
    }
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }

  private onImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    let route = this.nextRoute.indexOf('fn=') != -1 ? this.routePath : this.nextRoute;
    if (this.query) {
      this.router.navigate([route], { queryParams: { fn: this.query } });
    } else {
      this.router.navigate([route]);
    }
  }

  public getImageAction() {
    const currentPage = this.localStorageService.getItem('selectedMenuItem');
    if (currentPage === 'series') {
      this.localStorageService.setItem('storyID', '');
    }
    if (this.checkActionType && !this.comicData.button) {
      this.comicService.navigateByActions(this.comicData);
    } else if (!this.isWebsite()) {
      this.onImageClick()
    }
  }

  public getNavigateUrl(): string {
    if (this.comicData && !this.comicData.action) {
      return null;
    }
    const action = this.comicService.resolvePath(this.currentUrl, this.comicData.action);
    return this.comicService.resolveJsonInAction(action);
  }

  public getViewsForExternalLink() {
    this.comicService.viewsForExternalLink(this.comicAction);
  }
}
