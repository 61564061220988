<div class="list-item" [ngClass]="{'first-item': listItem.firstComic, 'first-episode': listItem.title === 'First Episode'}">
  <a *ngIf="isWebsite()" class="navigation-section" (click)="getViewsForExternalLink()" [href]="comicAction" target="_blank">
    <img class="episode-img" [src]="getImgUrl(listItem.image)" onError="this.src='../../../assets/images/new-icons/preview-image-not-available.jpg'">
    <div class="p-2 w-100 d-flex flex-wrap" [class.mx-1]="!isMobile">
      <div class="d-flex w-100 flex-row">
        <div class="p-bold title">{{listItem.title}}</div>
        <img src="../../assets/images/new-icons/icons8-external-link-100.png" class="mt-0 ml-auto external-icon" alt="External Link">
      </div>
      <div class="align-self-end w-100">
        <div class="d-flex justify-content-between">
          <span class="body-mini date" *ngIf="!isMobile">{{listItem.time}}</span>
          <span class="body-mini date" *ngIf="isMobile">{{listItem.time | date:'MMM d, y' }}</span>
          <span class="body-mini" [class.mobile-view]="isMobile">{{listItem.views | numberFormat}}
            <span *ngIf="isMobile"><img [src]="viewIcon" class="viewIcon" alt="Views"></span>
            <span class="body-mini" *ngIf="!isMobile">{{listItem.views == '1' ? 'View' : 'Views'}}</span>
          </span>
        </div>
      </div>
    </div>
  </a>
  <a *ngIf="!isWebsite()" class="navigation-section" (click)="getImageAction()" [routerLink]="comicAction">
    <img class="episode-img" [src]="getImgUrl(listItem.image)" onError="this.src='../../../assets/images/new-icons/preview-image-not-available.jpg'">
    <div class="p-2 w-100 d-flex flex-wrap" [class.mx-1]="!isMobile">
      <div class="d-flex w-100 flex-row">
        <div class="p-bold title">{{listItem.title}}</div>
        <img *ngIf="isPremium" [src]="srcPremium" class="mt-1 ml-auto external-icon" alt="External Link">
      </div>
      <div class="align-self-end w-100">
        <div class="d-flex justify-content-between">
          <span class="body-mini date" *ngIf="!isMobile">{{listItem.time}}</span>
          <span class="body-mini date" *ngIf="isMobile">{{listItem.time | date:'MMM d, y' }}</span>
          <span class="body-mini" [class.mobile-view]="isMobile">{{listItem.views | numberFormat}}
            <span *ngIf="isMobile"><img [src]="viewIcon" class="viewIcon" alt="Views"></span>
            <span class="body-mini" *ngIf="!isMobile">{{listItem.views == '1' ? 'View' : 'Views'}}</span>
          </span>
        </div>
      </div>
    </div>
  </a>
</div>
