import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComicService } from '../services/comic.service';
import 'firebase/functions';
import { isPlatformBrowser } from '@angular/common';
import { PanelService } from '../services/panel/panel.service';
import { subscribeURL, templateTypes, upgradeTitle, signUpURL } from '../constants/common.constants';
// import { signUpURL, subscribeURL, upgradeTitle } from '../constants/common.constants';
import * as util from '../utilities/common.util';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { NewComicService } from '../new-comic.service';
import { UserService } from '../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FollowModalComponent } from '../components/follow-modal/follow-modal.component';
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() series;
  @Input() comicData;
  @Input() actionUrls: any;
  @Input() isUpload: boolean = false;
  @Input() uploadURL: string = '';
  @Input() isRounded: any;
  @Input() productIds: any;
  @Output() modal = new EventEmitter();
  @Output() clicked = new EventEmitter();
  @Input() indexOfelement: any;
  @Input() notificationColumn: boolean;
  @Input() isCreatorUI: boolean;
  public showAllComics: boolean = true;
  @Output() showComics = new EventEmitter<string>();
  imgUrl: string;
  nextRoute: string;
  currentUrl: string;
  userSubscription: Subscription;
  currentDevice: string;
  routePath: string;
  query: string;
  comicAction: any;
  isHomePage: string;
  prodIds: any;
  panelShow: boolean;
  checkActionType: boolean;
  prodID: any;
  comic: boolean;
  subscribeURL = subscribeURL;
  upgradeTitle = upgradeTitle;
  isMobileView: boolean;
  isSeriesPage: boolean;
  templateType: string;
  templateMsg = 'Bonus Panel for Followers';
  isDescriptionLink: boolean;
  isSubscriber: boolean;
  hideFollow: boolean;
  modalRef: any;


  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    public comicService: ComicService,
    public newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    public panelService: PanelService,
    private readonly userService: UserService,
    private modalService: BsModalService
  ) {
    this.comicService.productIDs$.subscribe(val => {
      this.prodID = (val && JSON.parse(val)) || this.localStorageService.getItem('productID') || [];
      this.isSubscriber = this.prodID.length > 0;
      
      this.subscribedComic(this.comicData);
    });
    this.prodID = this.localStorageService.getItem('productID') || [];
    this.isSubscriber = this.prodID.length > 0;
  }
  shareData = {
    title: 'Tinyview',
    text: 'Comics App',
    url: 'https://tinyview.com' + this.router.url,
  };

  ngOnInit() {
    if (this.comicData && this.comicData.template === templateTypes.comicLimit) {
      this.templateType = templateTypes.comicLimit;
      this.templateMsg = 'Get Unlimited Access';
    } else if (this.comicData && this.comicData.template === templateTypes.premium) {
      this.templateType = templateTypes.premium;
      this.templateMsg = 'Subscribers-Only Comic';
    } else if (this.comicData && this.comicData.title) {
      this.templateMsg = this.isSubscriber ? 'Bonus Panel for Subscribers' : 'Bonus Panel for Followers';
      this.templateType = templateTypes.bonus;
    }
    
    if (window.innerWidth <= 991) {
      this.isMobileView = true;
    }
    if (isPlatformBrowser(this.platform)) {
      this.subscribedComic(this.comicData);
      this.newComicService.getsidebarItemSelected().subscribe((val) => {
        if (val && !val.sameItemClicked) {
          if (val && val.menuItem === 'home') {
            this.showAllComics = true;
          } else if (val && val.menuItem === 'following') {
            this.showAllComics = false;
          }
        }
      }, err => {
        console.log(err);
        this.showAllComics = false;
      })
    }
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.checkAction(this.comicData);
    this.nextRoute = this.getNavigateUrl();
    if (this.nextRoute && this.nextRoute.indexOf('fn=') != -1) {
      this.query = this.nextRoute.slice(this.nextRoute.indexOf('fn=') + 3, this.nextRoute.length);
      this.routePath = this.nextRoute.slice(0, this.nextRoute.indexOf('?'));
    }
    this.setBonusPanel();
    this.checkSeriesPage();
    if (this.comicData && !this.comicData.template) { // condition for default template
      if (this.comicData.action && !this.comicData.button && !this.comicData.image) {
        this.isDescriptionLink = true;
      }
    }
    if (this.comicData.action === 'follow' && this.series.action) this.setFollowBtn();
  }

  async setFollowBtn() {
    const userData = await this.userService.getUserDetails();
    const alerts = userData.data.data.alerts;
    const series = this.series.action;
    const normalizedSeries = series && series.replace(/^\/|\/index\.json$/g, '');
    let isFollowing = normalizedSeries && alerts.hasOwnProperty(normalizedSeries);
    if (isFollowing) {
      this.hideFollow = true
    } else {
      this.hideFollow = false
    }
  }

  private async setBonusPanel() {
    if (isPlatformBrowser(this.platform)) {
      if (this.comicData && ([templateTypes.bonus, templateTypes.premium, templateTypes.comicLimit].includes(this.comicData.template))) {
        if (this.currentUrl.includes('/edit')) {
          this.panelShow = true;
        }
        // Check if the bonus panel is unlocked using influence points.
        this.unlockBonusPanel();
        await this.checkForBonusPanel();
      } else {
        this.panelShow = true;
      }
    }
  }
  private async checkForBonusPanel() {
    const userData = await this.userService.getUserDetails();
    const alerts = userData.data.data.alerts;
    const normalizedSeries = this.series && this.series.action.replace(/^\/|\/index\.json$/g, '');
    const isCurrentSeriesFollower = Object.keys(alerts).includes(normalizedSeries);
    if ((this.isSubscriber || isCurrentSeriesFollower) && (this.comicData && this.comicData.template !== templateTypes.premium)) {
      this.panelShow = true;
    }
  }

  private checkSeriesPage() {
    this.isSeriesPage = this.comicData && this.comicData.image && this.comicData.image.includes('/cover') && this.indexOfelement === 0
  }

  public isGiftProduct = (proId) => {
    const productId = proId.toLowerCase();
    return productId.includes('gifts')
  }
  public toShowAllComics(): boolean {
    const flag = util.showAllComics();

    this.showAllComics = flag
    return flag;
  }
  public subscribedComic(imageData) {
    if (isPlatformBrowser(this.platform)) {
      if (this.isCreatorUI) {
        this.comic = true;
      } else if (imageData && imageData.inAppProducts) {
        for (const key in imageData.inAppProducts) {
          const reqObject = imageData.inAppProducts[key];
          const requirePurchaseKey = Object.keys(reqObject)[0];
          const needsPurchase = reqObject[requirePurchaseKey];
          this.comic = false;
          const productPurchased = (this.prodID || []).includes(requirePurchaseKey);
          if (needsPurchase && productPurchased) {
            this.comic = true;
            break;
          } else if (!needsPurchase && !productPurchased) {
            this.comic = true;
          } else if (!needsPurchase && productPurchased) {
            this.comic = false;
            break;
          }
        }
      } else {
        this.comic = true;
      }
    }
  }

  getImageAction() {
    if (this.comicAction === signUpURL) return this.router.navigate([signUpURL]);
    if (this.checkActionType) {
      this.navigateByActions()
    } else if (!this.isWebsite()) {
      this.onImageClick()
    }
  }

  public checkAction(comicData) {
    if (comicData.action === 'download-app-android' || comicData.action === 'download-app-ios' || comicData.action === 'follow') {
      this.checkActionType = true;
    } else {
      this.checkActionType = false;
    }
  }

  public async navigateByActions() {
    if (this.comicData.action == 'follow') {
      const seriesName = this.currentUrl.split('/')[1];
      const followResult = await this.comicService.addRemoveLike(`${this.series.action}`, true, false, {showToast: true, series: this.series});
      if (followResult.success) {
        window.location.reload();
      }  else if (followResult.openFollowModal) {
          this.openFollowModal();
        }
    } else {
      this.comicService.navigateByActions(this.comicData)
    }
  }

  openFollowModal() {
    if (this.modalRef) this.modalRef.hide();

    const initialState = {
      tinyviewPage: false,
      isFollowingAlready: false,
      series: this.series
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }


  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image, this.currentUrl);
  }

  public getNavigateUrl(): string {
    return this.comicService.getNavigateUrl(this.comicData);
  }

  // public getPanelBorder(): Object {
  //   let res = {};
  //   if (!this.newComicService.isComicPage() && !this.isUpload) { // hide on other pages if not comic page
  //     return res;
  //   }
  //   if (this.comicData && (this.comicData.panelBorder == undefined || this.comicData.panelBorder == null)) {
  //     return res;
  //   }
  //   res['border-color'] = this.comicData ? this.comicData.panelBorder['border-color'] : 'none';
  //   res['border-style'] = 'solid';
  //   res['border-left'] = 'none';
  //   res['border-right'] = 'none';
  //   res['padding'] = '20px 0px';
  //   res['border-bottom'] = this.comicData ? !this.comicData.panelBorder['border-bottom'] ? 'none' : this.comicData.panelBorder['border-bottom'] : 'none';
  //   res['border-top'] = this.comicData ? !this.comicData.panelBorder['border-top'] ? 'none' : this.comicData.panelBorder['border-top'] : 'none';
  //   res['border-width'] = this.comicData ? typeof this.comicData.panelBorder['border-width'] == 'number' ? this.comicData.panelBorder['border-width'] + 'px' : this.comicData.panelBorder['border-width'] : 'none';
  //   return res;
  // }

  public getImageBorder(): Object {
    return this.comicService.getImageBorder(this.comicData, this.isUpload);
  }

  // public getSeparatorBorder(): Object {
  //   return this.comicService.getSeparatorBorder(this.comicData);
  // }

  public onImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    let route = this.nextRoute.indexOf('fn=') != -1 ? this.routePath : this.nextRoute;
    if (this.query) {
      this.router.navigate([route], { queryParams: { fn: this.query } });
    } else {
      this.router.navigate([route]);
    }
    this.clicked.emit();
  }
  private unlockBonusPanel() {
    const storedURLs = this.sessionStorageService.getItem('unlockedURLs');
    if (storedURLs) {
      let urls = null;
      try {
        urls = JSON.parse(storedURLs);
      } catch (e) {
        urls = storedURLs;
      }
      if (urls.find(url => url.indexOf(this.currentUrl) > -1)) {
        this.panelShow = true;
      }
    }
  }

  public redirect() {
    if (!this.isDescriptionLink) return;
    window.open(this.comicData.action, "_blank");
  }
}
