<div class="gift-container" [class.m-0]="isDirectoryPage || isSubscribePage">
    <div class="images d-flex align-items-center" [class.p-top-2]="isMobile">
        <ng-container *ngFor="let gift of reactionData">
            <img [src]="gift.imageSrc"
                *ngIf="gift.show"
                (click)="openGiftModal(giftModal)"/>
        </ng-container>
        <span>
            <div class="p-bold" (click)="openGiftModal(giftModal)" [class.body-mini-bold]="isMobile">{{likeCount ? (likeCount | numberFormat) :'' }}</div>
        </span>
        <p class="p-bold" [class.body-mini-bold]="isMobile" *ngIf="!likeCount">Be first to like</p>
    </div>
    <div class="story-views shift-count" [class.mt-1]="isMobile">
        <div class="view-count d-flex">
            <span class="p-bold" [class.body-mini-bold]="isMobile">{{viewCount | numberFormat}}&nbsp;</span>
            <p *ngIf="viewCount == 1" [class.body-mini]="isMobile">View</p>
            <p *ngIf="(viewCount == 0 || viewCount > 1)" [class.body-mini]="isMobile">Views</p>
        </div>
    </div>
    <div [class.mt-1]="isMobile">
        <a (click)="showALlComments(storyID, modal, commentModal, true)">
            <div class="comments-count d-flex">
                <span class="p-bold" [class.body-mini-bold]="isMobile">{{commentCount | numberFormat}}&nbsp;</span>
                <p *ngIf="commentCount == 1" [class.body-mini]="isMobile">Comment</p>
                <p *ngIf="(commentCount == 0 || commentCount > 1)" [class.body-mini]="isMobile">Comments</p>
            </div>
        </a>
    </div>
</div>

<div class="bottom-section align-items-center" [ngClass]="{'mx-0 pb-0': isDirectoryPage}">
    <!-- [class.mx-4]="!isDirectoryPage && !isSubscribePage"> // REMOVED THIS CODE FOR COM 3144 -->
    <!-- <a (click)="doLike(storyID)"> -->
    <a (click)="openGiftArtistModal(giftArtistModal)" class="d-flex align-items-center">
        <img class="icon-20" *ngIf="!isLike" src="../../assets/images/new-icons/like.png" alt="like">
        <img *ngIf="isLike" src="./assets/images/red-heart.png" alt="like">&nbsp;
        <!-- <p *ngIf="likeCount"></p> -->
        <p *ngIf="!isLike">Like</p>
        <p *ngIf="isLike">Liked</p>
    </a>
    <a (click)="showALlComments(storyID, modal, commentModal, false)" class="d-flex align-items-center">
        <img class="icon-20" src="../../assets/images/new-icons/icons8-comment-100.png" alt="like" >&nbsp;
        <!-- <p class="like-count" *ngIf="commentCount"></p> -->
        <p>Comment</p>
    </a>
    <div class="popup">
        <!-- <a (click)="onShare(comicAction)"> -->
        <a (click)="openShareModal(shareModal)" class="d-flex align-items-center">
            <img class="icon-20" src="../../assets/images/new-icons/icons8-share-100-3.png"  alt="like">
            <span class="like-count"></span>
            <ng-container *ngIf="showShare">
                <div class="popuptext" id="myPopup">
                    <a (click)="shareFacebook()" class="fa">
                        <img src="../../assets/images/new-icons/icons8-facebook-100-3.png">
                    </a>
                    <a (click)="shareTwitter()" class="fa">
                        <img src="../../assets/images/new-icons/icons8-twitterx-100-outline.png">
                    </a>
                </div>
            </ng-container> &nbsp;
            <p>Share</p>
        </a>
    </div>
</div>
<ng-container *ngIf="!isDirectoryPage && !isSubscribePage">
    <app-comments [storyID]="storyID" [record]="record" [commentCount]="commentCount" [anonymousUser]="anonymousUser" 
        [storyData]="storyData" (updatedCommentCount)="updatedCommentCount($event)"></app-comments>
</ng-container>
<ng-template #modal>
    <div>
        <app-action-popup [modalRef]="modalRef" [ctaList]="ctaList" [likedComic]="likedComic"></app-action-popup>
    </div>
</ng-template>

<ng-template #giftModal>
    <app-gift-items [giftItemCount]="giftItemCount" [storyID]="storyID" [giftModalRef]="giftModalRef"> </app-gift-items>
</ng-template>

<ng-template #commentModal>
    <div class="action-container">
        <div class="text-center mb-2">
            <p>You need to complete your profile first.</p>
        </div>
        <div>
        <div class="btn-container pb-0">
            <button class="secondary-btn" aria-label="Open" (click)="commentModalRef.hide()">
                Cancel
            </button>
            &nbsp;
            <button class="btn tv-btn-red" aria-label="Open" (click)="goToSignInPage()">
                Edit Profile
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #giftArtistModal>
    <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="giftArtistModalRef.hide()" />
    <div class="heading">
        <p class="gift-artist-heading">Send love or more to the artist...</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let gift of availableGifts">
            <div *ngIf="!gift.hide" class="gift-item" [ngClass]="{'disable-gift': alreadyGifted(gift)}" (click)="addReaction(gift.reactionName, gift.productId, gift.cost)">
                <img class="gift-img" src="{{ getImgUrl(gift) }}">
                <span class="gift-title">{{ getGiftTitle(gift) }}</span>
                <span class="gift-price">{{ gift.price }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
    <div *ngIf="isCheckoutURLLoading" class="loader-bar center-loader"></div>
</ng-template>

<ng-template #shareModal>
    <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="shareRef.hide()" />
    <div class="heading">
        <h2 class="share-heading">Share it with friends</h2>
        <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
        <p class="share-sub-heading" *ngIf="isSubscriber">and help creators by spreading the word</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let share of shareConfig">
            <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
                <img class="share-img" [src]="share.image">
                <span class="share-title">{{ share.title }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
</ng-template>
