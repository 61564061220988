<div class="side-container" *ngIf="showMenu">
  <div class="row pt-4 mb-50 border-bottom-white">
    <div class="ml-5">
      <img class="profile-img mt-3" [src]="userInfo.img || noImageUser" [class.image-border]="!!userInfo.img"
        alt="profile-img" onError="this.src='../../../assets/images/male-user-100.png'">
      <div class="d-flex" *ngIf="isAnonymousUser">
        <h1 class="ml-2">Guest User</h1>
        <img class="subs-icon" *ngIf="userInfo.subscription" [src]="userInfo.subscription" alt="subscription-icon">
      </div>
    </div>
    <div class="col-10 mt-3 d-flex flex-wrap align-items-center ml-5 mb-1" *ngIf="!isAnonymousUser">
      <h2>{{userInfo.name || userInfo.phone}}</h2>
      <img class="subs-icon" *ngIf="userInfo.subscription" [src]="userInfo.subscription" alt="subscription-icon">
    </div>
    <div (click)="navigateTo('edit-profile')" class="error-text ml-5 tv-link"
      *ngIf="isIncompleteProfile && !isAnonymousUser">
      Complete profile
    </div>
    <div class="col-10 d-flex flex-column mx-5 h-30" *ngIf="isAnonymousUser">
      <div class="no-hover ml-2" (click)="getRedirectionAfterSignin(activeIcon)">
        <h2 class="px-auto">Sign In</h2>
      </div>
      <p class="ml-2 mt-1">
        New user? <span class="tv-link" (click)="signUp()">Sign Up</span>
      </p>
    </div>
    <p class="ml-5 body-mini mb-1 user-contact"
      *ngIf="!isAnonymousUser && !isIncompleteProfile && userInfo && userInfo.phone">{{ userInfo.phone }}</p>
    <p class="ml-5 body-mini user-contact"
      *ngIf="!isAnonymousUser && !isIncompleteProfile && userInfo && userInfo.formattedEmailName">{{
      userInfo.formattedEmailName }}</p>

    <a class="col-10 mx-3 mt-60 d-flex flex-wrap" *ngIf="activeIcon !== '' && activeIcon !== '/following'"
      (click)="goBack()">
      <img class="icons" [src]="getIconUrl('back')" alt="back-icon">
      <p>Back</p>
    </a>

    <a class="col-10 mx-3 mt-2 d-flex flex-wrap"
      [ngClass]="{ 'mt-2': (activeIcon !== '' && activeIcon !== '/following'), 'mt-60': (activeIcon === '' || activeIcon === '/following'), 'on-page': activeIcon === '' }"
      (click)="navigateTo('home')">
      <img class="icons" [src]="getIconUrl('home')" alt="home-icon">
      <p>Home</p>
    </a>
    <a class="col-10 mx-3 my-2 d-flex flex-wrap" [ngClass]="{'on-page': activeIcon === '/following'}"
      (click)="navigateTo('tickbox')">
      <img class="icons" [src]="getIconUrl('/following')" alt="following-icon">
      <p>Following</p>
    </a>
    <a class="col-10 mx-3 mb-50 d-flex flex-wrap"
      [ngClass]="{'on-page': activeIcon === '/tinyview/comic-series-directory'}"
      (click)="navigateTo('signpost', $event)">
      <img class="icons" [src]="getIconUrl('/tinyview/comic-series-directory')" alt="directory-icon">
      <p>All Series</p>
    </a>
  </div>

  <div class="row mb-50">
    <a class="col-10 mx-3 d-flex flex-wrap align-items-center" (click)="navigateTo('user')">
      <img class="icons" [src]="getIconUrl('user')" alt="account-icon">
      <p>
        Account
        <img *ngIf="isIncompleteProfile" src="../../assets/images/new-icons/icons8-box-important-100.png"
          class="icon-16 ml-2">
      </p>
      <img [src]="getDropdownIcon('user')" class="chevron my-auto ml-auto mr-5">
    </a>
    <!-- For User Dropdown List -->
    <div class="mt-2 mx-4 dropdown" *ngIf="isUserDropdownOpen">
      <a *ngIf="!isAnonymousUser" (click)="navigateTo('edit-profile')" class="d-flex mx-3 mt-1 align-items-center"
        [ngClass]="{'on-page': activeIcon === '/edit-profile'}">
        <img class="icons" [src]="getIconUrl('/edit-profile')" alt="Edit Profile">
        <p>
          Edit Profile
          <img *ngIf="isIncompleteProfile" src="../../assets/images/new-icons/icons8-box-important-100.png"
            class="icon-16 ml-2">
        </p>
      </a>
      <a (click)="navigateTo('subscribe')" class="d-flex mx-3 mt-1"
        [ngClass]="{'on-page': activeIcon === '/tinyview/subscribe'}">
        <img class="icons" [src]="getIconUrl('/subscribe')" alt="Subscribe">
        <p>
          Subscribe
        </p>
      </a>
      <a *ngIf="isSubscribed" (click)="navigateTo('manage-subscription')" class="d-flex mx-3 mt-1">
        <img class="icons" [src]="getIconUrl('manage-subscription')" alt="Manage Subscription">
        <p>Manage Subscription</p>
      </a>
    </div>
    <a *ngIf="!isSubscribed" class="col-10 mx-3 mt-2 d-flex flex-wrap" (click)="navigateTo('influence')"
      [ngClass]="{'on-page': activeIcon === '/tinyview/influence-points'}">
      <img class="icons" [src]="getIconUrl('/tinyview/influence-points')" alt="influencePoints-icon">
      <p>Influence Points</p>
    </a>
    <!-- Friends Flow -->
    <a class="col-10 mx-3 mt-2 d-flex flex-wrap" (click)="navigateTo('friends')">
      <img class="icons" [src]="getIconUrl('friends')" alt="Friends-icon">
      <p>Friends</p>
      <img [src]="getDropdownIcon('friends')" class="chevron my-auto ml-auto mr-5">
    </a>
    <!-- For Friends Dropdown List -->
    <div class="mt-2 mx-4 dropdown" *ngIf="isFriendsDropdownOpen">
      <a (click)="openInviteFriendsModal(inviteFriendsModal)" class="d-flex mx-3 mt-1">
        <img class="icons" [src]="getIconUrl('invite-friends')" alt="Invite Friends">
        <p>Invite Friends</p>
      </a>
      <a (click)="navigateTo('see-friends')" class="d-flex mx-3 mt-2"
        [ngClass]="{'on-page': activeIcon === '/see-friends'}">
        <img class="icons" [src]="getIconUrl('/see-friends')" alt="See Friends">
        <p>
          See Friends
        </p>
      </a>
      <a (click)="navigateTo('friend-request')" class="d-flex mx-3 mt-2"
        [ngClass]="{'on-page': activeIcon === '/friend-request'}">
        <img class="icons" [src]="getIconUrl('/friend-request')" alt="Friend Request">
        <p>
          Friend Requests
        </p>
      </a>
    </div>

    <!-- Settings Flow -->
    <a class="col-10 mx-3 mt-2 d-flex flex-wrap" (click)="navigateTo('settings')">
      <img class="icons" [src]="getIconUrl('settings')" alt="Settings-icon">
      <p>Settings</p>
      <img [src]="getDropdownIcon('settings')" class="chevron my-auto ml-auto mr-5">
    </a>

    <!-- For Settings Dropdown List -->
    <div class="mt-2 mx-4 dropdown" *ngIf="isSettingsDropdownOpen">
      <a [routerLink]="['/manage-alerts']" class="d-flex mx-3 mt-1"
        [ngClass]="{'on-page': activeIcon === '/manage-alerts'}">
        <img class="icons" [src]="getIconUrl('/manage-alerts')" alt="Manage Alerts">
        <p>Manage Alerts</p>
      </a>
    </div>

    <!-- Only for creators -->
    <a *ngIf="(canEdit && (isAdmin || tinyviewAdmin)) || (!canAdd && (isAdmin || tinyviewAdmin)) || (canEdit && (isAdmin || tinyviewAdmin) && canAdd) || (isAnalysts || tinyviewAdmin)"
      class="col-10 mx-3 mt-2 d-flex flex-wrap" (click)="navigateTo('creator')">
      <img class="icons" [src]="getIconUrl('creator')" alt="creator-icon">
      <p>Creators</p>
      <img [src]="getDropdownIcon('creator')" class="chevron my-auto ml-auto mr-5">
    </a>
    <!-- For Creator Dropdown List -->
    <div class="mt-2 mx-4 dropdown" *ngIf="isCreatorDropdownOpen">
      <a *ngIf="isSeriesPage && canAdd && (isAdmin || tinyviewAdmin)" (click)="onEdit(true,showModal)"
        class="d-flex mx-3 mt-1">
        <img class="icons" [src]="getIconUrl('/add-comics')" alt="Add Comic Icon">
        <p>
          Add Comics
        </p>
      </a>
      <a *ngIf="isSeriesPage && !canAdd && (isAdmin || tinyviewAdmin)" (click)="onEdit(true,showModal)"
        class="d-flex mx-3 mt-1">
        <img class="icons" [src]="getIconUrl('/add-comics')" alt="Add Comic Icon">
        <p>
          Add Comic
        </p>
      </a>
      <a *ngIf="showEditComic && canEdit && (isAdmin || tinyviewAdmin)" (click)="onEdit(false,'')"
        class="d-flex mx-3 mt-1">
        <img class="icons" [src]="getIconUrl('/edit')" alt="Edit Comic Icon">
        <p>Edit Comic</p>
      </a>
      <a *ngIf="(isAdmin || tinyviewAdmin) && isSeriesPage" class="d-flex mx-3 mt-1"
        (click)="openLinkModal(showLinkModal)">
        <img class="icons" [src]="getIconUrl('/add-link')" alt="Add Link Icon">
        <p>Add Link</p>
      </a>
      <a [routerLink]="['/notification', scheduleAlertsURL]" class="d-flex mx-3 mt-1"
        *ngIf="canEdit && (isAdmin || tinyviewAdmin) && canAdd && (isSeriesPage || (isComicPage && !influencePointsPage && !isSubscribePage) || isStoryPage)">
        <img class="icons" [src]="getIconUrl('/schedule-alerts')" alt="Schedule Alerts Icon">
        <p>Schedule Alerts</p>
      </a>
      <a [routerLink]="['/dashboard']" class="d-flex mx-3 mt-1" *ngIf="(tinyviewAdmin || isAnalysts)">
        <img class="icons" [src]="getIconUrl('/dashboard')" alt="Dashboard Icon">
        <p>Main Dashboard</p>
      </a>
      <a [routerLink]="['/dashboard/creators']" class="d-flex mx-3 mt-1"
        *ngIf="(isAnalysts || tinyviewAdmin || isAdmin)">
        <img class="icons" [src]="getIconUrl('/dashboard')" alt="Dashboard Icon">
        <p>Series Dashboard</p>
      </a>
      <a [routerLink]="['/dashboard/referrals']" class="d-flex mx-3 mt-1" *ngIf="isSeriesCreator">
        <img class="icons" [src]="getIconUrl('/referrals')" alt="Dashboard Icon">
        <p>Referral Revenue</p>
      </a>
      <a [routerLink]="['/dashboard/top-referrer']" class="d-flex mx-3 mt-1"
        *ngIf="(isAnalysts || tinyviewAdmin)">
        <img class="icons" [src]="getIconUrl('/dashboard')" alt="Dashboard Icon">
        <p>Top Referrers</p>
      </a>
      <a [routerLink]="['/dashboard/totals']" class="d-flex mx-3 mt-1" *ngIf="(isAnalysts || tinyviewAdmin)">
        <img class="icons" [src]="getIconUrl('/dashboard')" alt="Dashboard Icon">
        <p>Totals Dashboard</p>
      </a>
    </div>
    <a class="col-10 mx-3 mt-2 d-flex flex-wrap" (click)="navigateTo('legal')">
      <img class="icons" [src]="getIconUrl('legal')" alt="Legal-icon">
      <p>Legal</p>
      <img [src]="getDropdownIcon('legal')" class="chevron my-auto ml-auto mr-5">
    </a>
    <!-- For Legal Dropdown List -->
    <div class="mt-2 mx-4 dropdown" *ngIf="isLegalDropdownOpen">
      <a (click)="navigateTo('/terms-conditions')" class="d-flex mx-3 mt-1"
        [ngClass]="{'on-page': activeIcon === '/terms-conditions'}">
        <img class="icons" [src]="getIconUrl('/terms-conditions')" alt="Terms and Conditions">
        <p>Terms of Use</p>
      </a>
      <a (click)="navigateTo('/privacy-policy')" class="d-flex mx-3 mt-2"
        [ngClass]="{'on-page': activeIcon === '/privacy-policy'}">
        <img class="icons" [src]="getIconUrl('/privacy-policy')" alt="Privacy Policy">
        <p>
          Privacy Policy
        </p>
      </a>
    </div>
    <a class="col-10 mx-3 mt-2 d-flex flex-wrap" href="mailto:support@tinyview.zendesk.com?body={{UID}}">
      <img class="icons" [src]="getIconUrl('contact-us')" alt="contact-icon">
      <p>Contact Us</p>
    </a>
    <a class="col-10 mt-2 mx-3 mb-4 d-flex flex-wrap" *ngIf="!isAnonymousUser" (click)="navigateTo('logout')">
      <img class="icons"
        [src]="userInfo.phone ? '../../../assets/images/new-icons/icons8-sign-out-100-2.png' : '../../../assets/images/new-icons/icons8-sign-in-100.png'"
        alt="signout-icon">
      <p>Sign Out</p>
    </a>
  </div>
</div>

<!-- Modals -->
<ng-template #showModal>
  <div class="show-modal">
    <h3>Can’t Add Comic</h3>
    <p>Please create a panel with the title “Latest Comics” and try again. New comics will be added under this panel.
    </p>
    <button class="secondary-btn" aria-label="Open" (click)="modalRef.hide()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #showLinkModal>
  <app-external-link [modalLinkRef]="modalLinkRef" [fromComicPannel]="false"></app-external-link>
</ng-template>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>