<ng-container *ngIf="!errorMsg">
  <div class="section-container">

    <div class="tv-columns" *ngIf="!isLoadingHeader">
          <!-- OPEN IN APP -->
    <!-- Encourage to Subscribe -->
    <app-top-banner *ngIf="currentSeriesDetails.action && currentSeriesDetails.title" [followBtnText]="followBtnText" [hasFollowBtn]="hasFollowBtn" [hasBonusPanel]="hasBonusPanel" [series]="currentSeriesDetails" (onPropertyChange)="onBannerChange($event)" [isTopBanner]="true" [showTo]="resp?.comics && resp?.comics['show-to']?.key"></app-top-banner>

    <!-- Follow Series Banner-->
    <!-- <app-follow-banner [isTopBanner]="true" [hasAnyChannel]="hasAnyChannel" [seriesName]="seriesName" [seriesTitle]="seriesTitle" *ngIf="!showNotifyForSubscribe && !(this.isMobileView  && isOpenApp)" [likeCount]="likeCount" [followBtnText]="followBtnText" (onPropertyChange)="onBannerChange($event)"></app-follow-banner> -->
      <!-- For Header of Home, Following, Series, Comic -->
      <div *ngIf="!storyPage" class="comic-desc-container" [ngClass]="{'comic-desc-comic-page': isComicPage}">
        <img [src]="panelService.getImgUrl(resp?.comics?.ogImage, currentUrl)" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
        <!-- NEW CARD -->
        <div class="tv-card pt-4" *ngIf="resp && !isComicPage">
          <!-- SERIES, COMIC IMAGE -->
          <img class="series-profile" [class.cursor-pointer]="currentUrl !== '/tinyview'" src="{{this.seriesImage || 'https://storage.googleapis.com/tinyview-dev.appspot.com/tinyview/tinyview-profile.jpg'}}" alt="Loading..."
            (click)="currentUrl !== '/tinyview' ? navigateTo('/tinyview') : null">
          <!-- TITLE -->
          <div class="d-flex justify-content-between mr-3" [class.mb-3]="isHomePage">
            <a>
              <h1 *ngIf="resp?.comics?.title && !storyPage" class="my-auto" (click)="isDirectoryPage ? navigateTo('/tinyview') : null">
                {{ !isDirectoryPage && !isSubscribePage  ? resp.comics.title : 'Tinyview'}}
              </h1>
            </a>
            <span (click)="addRemoveLike()" *ngIf="isSubscribePage || isDirectoryPage" class="my-auto">
              <div class="loader-bar-btn mr-5" *ngIf="showLoader"></div>
              <a *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? '../../assets/images/new-icons/tick-box-100.png' : '../../assets/images/new-icons/icons8-add-100.png' }}">
                <p>{{followBtnText}}</p>
              </a>
            </span>
          </div>
          <h2 class="mb-2 mt-3" *ngIf="resp?.comics?.title && !storyPage && (isDirectoryPage || isSubscribePage)">
            {{ resp.comics.title }}
            <p class="my-2">{{comicDate}}</p>
            <!-- <img src="../../assets/images/new-icons/icons8-quote-50.png" class="quotes" alt="Quotes"> -->
            <p class="comments mt-2 mb-3" [innerHTML]="comicComments | stripTags: 'br' :'p' :'i' :'b'"></p>
          </h2>
          <h1 *ngIf="resp?.data?.data?.title && storyPage">
            {{resp.data.data.title}}
          </h1>
          <!-- VIEWS & LIKES -->
          <div class="like-view-wrapper-mobile mt-3" *ngIf="!isDirectoryPage && !isSubscribePage">
            <!-- VIEWS -->
            <div class="d-flex p-bottom">
              <ng-container *ngIf="pageInfo?.data?.viewCount || viewCount">
                <span class="p-bold" *ngIf="pageInfo?.data || viewCount">{{(pageInfo?.data?.viewCount || viewCount) | numberFormat}}&nbsp;</span>
                <p> View<span *ngIf="pageInfo?.data?.viewCount != 1">s</span> </p>
              </ng-container>
              <ng-container *ngIf="pageInfo || likeCount">
                <span class="d-flex ml-3" *ngIf="(pageInfo && (pageInfo?.data?.likeCount || pageInfo?.data?.likeCount == 0)) || likeCount">
                  <span class="p-bold" *ngIf="(pageInfo && pageInfo?.data?.likeCount) && currentUrl != '' && currentUrl != '/'">
                    {{ pageInfo?.data?.likeCount | numberFormat}}
                  </span>
                  <span class="p-bold" *ngIf="likeCount && (currentUrl == '' || currentUrl == '/' || storyPage)">
                    {{likeCount | numberFormat}}
                  </span>
                  <span class="p-bold" *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 0))">0</span>
                  &nbsp;
                  <p *ngIf="isCover">Follower</p>
                  <p *ngIf="!isCover"> Like</p>
                  <p *ngIf="pageInfo?.data?.likeCount != 1">s</p>
                </span>
              </ng-container>
            </div>

            <!-- FOLLOW COMMENT SHARE -->
            <div class="header-action">
              <span (click)="addRemoveLike()" >
                <div class="loader-bar-btn" *ngIf="showLoader"></div>
                <a *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                  <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? '../../assets/images/new-icons/tick-box-100.png' : '../../assets/images/new-icons/icons8-add-100.png' }}">
                  <p>{{followBtnText}}</p>
                </a>
              </span>
              <!-- <a class="d-flex justify-content-center align-items-center">
                <img class="icon-20 mr-1" src="../../assets/images/new-icons/icons8-comment-100.png">
                <p>Comment</p>
              </a> -->
              <a (click)="openShareModal(shareModal)" class="d-flex justify-content-end align-items-center">
                <img class="icon-20 mr-1" src="../../assets/images/new-icons/icons8-share-100-3.png">
                <p>Share</p>
              </a>
            </div>

            <!-- DESCRIPTION -->
            <p class="pt-3" [class.pb-3]="isSeriesPage" *ngIf="isCover"
              [innerHTML]="resp?.comics?.description | stripTags: 'br' :'p' :'i' :'b'">
            </p>
          </div>
          <app-action-bar *ngIf="(isDirectoryPage || isSubscribePage) && !storyPage && pageInfo" [comicAction]="''" [storyData]="''" [record]="record"
          [isLike]="(pageInfo.data && pageInfo.data.isLiked) || null" [commentCount]="(pageInfo.data && pageInfo.data.commentCount) || 0" [repostCount]="(pageInfo.data && pageInfo.data.repostCount) || 0"
          [viewCount]="(pageInfo.data && pageInfo.data.viewCount) || 0" [likeCount]="(pageInfo.data && pageInfo.data.likeCount) || 0" [storyID]="(pageInfo.data && pageInfo.data.storyId) || ''"
          [giftItemCount]="(pageInfo.data && pageInfo.data.giftsCount) || {}" [giftedItems]="(pageInfo.data && pageInfo.data.giftedItems) || ''" [actionType]="''" [hideCommentSection]="true"
          [desc]="comicComments || resp?.comics?.description" [img]="resp?.comics?.ogImage" [title]="resp?.comics?.title || resp?.data?.data?.title">

        </app-action-bar>

          <!-- SOCIAL MEDIA LINKS -->
          <ng-container *ngIf="resp?.comics?.links">
            <div class="links-wrapper">
              <a [attr.href]="resp.comics.links.website" *ngIf="resp.comics.links.website">
                <button class="btn tv-btn-light col-12">
                  <p>
                    <img class="icon-20 mr-1" src="../../assets/images/new-icons/icons8-link-100.png" alt="icon">
                    {{ webUrl }}
                  </p>
                </button>
              </a>
              <div class="social-container mt-3">
                <div *ngIf="resp.comics.links.instagram">
                  <a [attr.href]="resp.comics.links.instagram" [title]="resp.comics.links.instagram">
                    <img class="icon-25" src="../../assets/images/new-icons/icons8-instagram-100-2.png" alt="icon">
                  </a>
                </div>
                <div *ngIf="resp.comics.links.facebook">
                  <a [attr.href]="resp.comics.links.facebook" [title]="resp.comics.links.facebook">
                    <img class="icon-25" src="../../assets/images/new-icons/icons8-facebook-100-3.png" alt="icon">
                  </a>
                </div>
                <div *ngIf="resp.comics.links.twitter">
                  <a [attr.href]="resp.comics.links.twitter" [title]="resp.comics.links.twitter">
                    <img class="icon-25" src="../../assets/images/new-icons/icons8-twitterx-100-outline.png" alt="icon">
                  </a>
                </div>
              </div>
            </div>
          </ng-container>

        </div>

        <!-- LOADER -->
        <img *ngIf="!fetchDone" src="../../assets/images/comic-header.gif" />

        <!-- COMIC PAGE -->
        <ng-container *ngIf="fetchDone">
          <div *ngIf="isComicPage" class="tv-card">
            <div class="series-sub-block" *ngIf="seriesImage || seriesTitle">
              <a [routerLink]="seriesHomeNavigation">
                <!-- SERIES IMAGE -->
                <img class="series-profile"  *ngIf="seriesImage" [src]="seriesImage">
                <!-- <p *ngIf="!seriesImage && seriesTitle">{{getFirstLetters(seriesTitle)}}</p> -->
              </a>
            </div>

            <!-- SERIES & FOLLOW BUTTON -->
            <div class="d-flex justify-content-between">
              <h1 *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation" class="my-auto">
                {{seriesTitle}}
              </h1>

              <!-- [ngClass]="followBtnText != 'Following' ? 'comic-page-btn':'follow-comic-app-btn'" -->

              <span (click)="doFollowForComicWriters()" class="my-auto"
                *ngIf="(!isCover && (currentUrl != '/tinyview/comic-series-directory')) && seriesTitle && (storyPage ? actionForStory : true)">
                <div class="loader-bar-btn mr-5" *ngIf="showLoader"></div>
                <p *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                  <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? '../../assets/images/new-icons/tick-box-100.png' : '../../assets/images/new-icons/icons8-add-100.png' }}">
                  {{followBtnText}}
                </p>
              </span>
            </div>
            <div class="mt-2">
              <app-comic-rating *ngIf="resp.comics.isRatingDataReady" [comic]="resp.comics"></app-comic-rating>
            </div>
            <!-- TITLE -->
            <h2 *ngIf="resp?.comics?.title && !storyPage" [class.mt-3]="!storyPage && seriesImage">
              {{resp.comics.title}}
            </h2>
            <h2 *ngIf="resp?.data?.data?.title && storyPage">
              {{resp.data.data.title}}
            </h2>

            <p *ngIf="comicDate && seriesImage && !isCover" class="my-2">{{comicDate}}</p>




            <ng-container *ngIf="isCover">
              <div class='like-view-wrapper-mobile'>
                <div *ngIf="pageInfo?.data?.viewCount && pageInfo?.data">
                  <span class="view-number" *ngIf="pageInfo?.data">{{pageInfo?.data?.viewCount |
                    numberFormat}}&nbsp;</span><br />
                  <ng-container *ngIf="pageInfo?.data?.viewCount && pageInfo?.data?.viewCount == 1 "> <span
                      class="view-text">
                      View </span>
                  </ng-container>
                  <ng-container *ngIf="pageInfo?.data?.viewCount > 1 || pageInfo?.data?.viewCount == 0"><span
                      class="view-text">
                      Views</span>
                  </ng-container>
                </div>
                <div *ngIf="pageInfo|| likeCount">
                  <div *ngIf="(pageInfo && (pageInfo?.data?.likeCount || pageInfo?.data?.likeCount == 0)) || likeCount">
                    <span class="like-number" *ngIf="(pageInfo && pageInfo?.data?.likeCount) && currentUrl != '' && currentUrl != '/'">{{
                      pageInfo?.data?.likeCount |
                      numberFormat}}</span>
                    <span class="like-number" *ngIf="likeCount && (currentUrl == '' || currentUrl == '/')">{{likeCount |
                      numberFormat}}</span>
                    <span class="like-number" *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 0))">0</span><br />
                    <ng-container *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 1))">
                      <span class="follower-text" *ngIf="isCover"> Follower</span>
                      <span class="like-text" *ngIf="!isCover"> Like</span>
                    </ng-container>
                    <ng-container
                      *ngIf="(pageInfo && pageInfo?.data?.likeCount == 0)|| (pageInfo && pageInfo?.data?.likeCount > 1)">
                      <span class="like-text" *ngIf="!isCover">&nbsp; Likes</span>
                      <span class="follower-text" *ngIf="isCover"> Followers</span>
                    </ng-container>
                  </div>
                </div>
                <div>
                  <button [ngClass]="followBtnText != 'Following'?'comic-btn':'follow-app-btn'"
                    (click)="addRemoveLike()">
                  <div class="loader-bar-btn" *ngIf="showLoader"></div>
                    <span *ngIf="!showLoader">{{followBtnText}}</span>
                  </button>
                </div>
              </div>
            </ng-container>
            <div class="comments" *ngIf="resp?.data?.data?.comment && storyPage"
              [innerHTML]="resp?.data?.data?.comment | stripTags: 'br' :'p' :'i' :'b'">
            </div>
            <app-panel-nav *ngIf="!isCover && currentUrl != '/tinyview/comic-series-directory' && storyPage && storyID"
              (doLike)="doLikefromComicPage($event)" [isLiked]="isLiked2" [commentCount]="commentCount"
              [viewCount]="viewCount" [likeCount]="likeCount" [giftedItems]="giftedItems" [storyID]="storyID"
              [giftItemCount]="giftsCounts" [actionType]="actionType">
            </app-panel-nav>

            <!-- <img *ngIf="resp?.comics?.comments && !storyPage" src="../../assets/images/new-icons/icons8-quote-50.png" class="quotes" alt="Quotes"> -->
            <div class="comments mb-3 mt-3" *ngIf="resp?.comics?.comments && !storyPage"
              [innerHTML]="resp.comics.comments | stripTags: 'br' :'p' :'i' :'b'">
            </div>
            <app-panel-nav *ngIf="!isCover && !['/tinyview/subscribe', '/tinyview/comic-series-directory'].includes(currentUrl) && !storyPage && storyID"
              (doLike)="doLikefromComicPage($event)" [isLiked]="isLiked2" [commentCount]="commentCount"
              [viewCount]="pageInfo?.data?.viewCount" [likeCount]="likeCount" [storyID]="storyID"
              [giftItemCount]="giftsCounts" [giftedItems]="giftedItems" [actionType]="actionType" [hideBottomSection]="true">
            </app-panel-nav>

            <div class="page-views" *ngIf="currentUrl == '/tinyview/comic-series-directory'">
              <span class="view-number" *ngIf="pageInfo && pageInfo?.data">{{pageInfo?.data?.viewCount |
                numberFormat}}&nbsp;</span>
              <ng-container *ngIf="pageInfo?.data?.viewCount && pageInfo?.data?.viewCount == 1 "> <span
                  class="view-text">
                  View </span>
              </ng-container>
              <ng-container *ngIf="pageInfo?.data?.viewCount > 1 || pageInfo?.data?.viewCount == 0"><span
                  class="view-text">
                  Views</span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- For Body -->
      <div class="comic-panel-container" [class.pb-58]="isMobileView && !storyPage"
        [ngClass]="{'comic-panel-comic-page': isComicPage, 'remove-bg-color': (isComicPage && !isSubscribePage) || isDirectoryPage}">
                <!-- 1. Subscribe Panel, for all pages except (Story Page and Subscribe Page) -->

        <!-- Progress Bar (this will call header.json and show progress from there conditionally) -->
        <ng-container *ngIf="!storyPage">
          <app-progress-bar></app-progress-bar>
        </ng-container>
        <!-- Panels Loading Indicator -->
        <div class="placeholder-image">
          <img *ngIf="!fetchDone && !storyPage && comicSeriesViewTabs.list && isSeriesPage" src="../../assets/images/new-icons/listview-placeholder.gif" />
          <img *ngIf="!fetchDone && !storyPage && !comicSeriesViewTabs.list && isSeriesPage" src="../../assets/images/content-placeholder.gif" />
          <img *ngIf="!fetchDone && !storyPage && !isSeriesPage" src="../../assets/images/content-placeholder.gif" />
        </div>
        <!-- 2. All other Panels, Note: Panels are shown based on the data prepared in chapterList Array -->
        <ng-container>
          <ng-container *ngFor="let list of chapterList; let indexOfelement=index;">
            <h1 *ngIf="list.title === 'Subscribe' && !isSubscribed && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <h1 *ngIf="list.title === 'Change Subscription' && isSubscribed && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <h1 *ngIf="list.title === 'Terms and Conditions' && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <!-- Progress Bar (this will show progress bar from comic chapters if template subscribers is there conditionally)-->
            <ng-container *ngIf="list.template === 'subscribers'">
              <app-progress-bar [progressBarData]="list"></app-progress-bar>
            </ng-container>

            <ng-container *ngIf="list.template === 'card'">
              <app-template-card [data]="list"></app-template-card>
            </ng-container>

            <ng-container *ngIf="list && list.action != 'influencePanel' ">
            <!-- 3. (a) Latest Comics, only title on Home, and Following Advertisement -->
            <!-- 3. (b) New Comic Series, only title, for Series Page -->
            <!-- 3. (c) Cover Image, for Series Page, Comic page, Subscribe Page -->
            <div class="image-container mt-3"
            *ngIf="(!list.template || [templateTypes.stories, templateTypes.newsfeed, templateTypes.bonus, templateTypes.premium, templateTypes.comicLimit].includes(list.template)) && list.title !== 'You May Also Like'">
              <ng-container *ngIf="list.title != 'Latest Comics'">
                <app-image [indexOfelement]='indexOfelement' [comicData]="list" (modal)="openModal(modal)"
                (clicked)="fromPanelClick = true" (showComics)="showComics()" [productIds]="productIds" [series]="currentSeriesDetails">
              </app-image>
              </ng-container>
              <!-- This block will be rendered on subscription page only -->
              <ng-container *ngIf="(currentUrl == subscribeURL) && (list.action == 'in-app-purchase')">
                <app-payments [subscriptionData]="list"></app-payments>
              </ng-container>
              <ng-container *ngIf="list.title == 'Latest Comics'">
                <div class="d-flex mx-3 px-1 mt-1 pt-3">
                  <h1>{{list.title}}</h1>
                  <div class="ml-auto pb-1 mb-1 d-flex">
                    <a class="mx-3" [class.selected-tab]="comicSeriesViewTabs.list" (click)="showListView()"><img [src]="listIcon" class="tabs-icon mx-2" alt="list-view"></a>
                    <a class="px-2" [class.selected-tab]="comicSeriesViewTabs.toc" (click)="showTocView()"><img [src]="tocIcon" class="tabs-icon" alt="toc-view"></a>
                  </div>
                </div>
                <p class="description">{{ list.description }}</p>
                <app-list-view *ngIf="firstEpisode" [listItemData]="firstEpisode"></app-list-view>
              </ng-container>
              <div
                *ngIf="(list.title == 'My Comics' && ((currentUrl == '/' || currentUrl == ''))
                && !isFollowComic && !showAllComics)
                && localStorageService.getItem('selectedMenuItem') === 'following'"
                class="message-block">
                <div class="p-bold">Follow series to see their latest comics here</div>
                <p>Since you are not following any series, there is nothing to show on your
                  home page.</p>
                <br /><button class="btn tv-btn-red" [routerLink]="['/tinyview/comic-series-directory']">Go To
                  All Series</button>
              </div>

              <!-- COMIC PAGE EXTRA DETAILS -->
              <h1 class="m-20 mt-3" *ngIf="list.comicDescription && currentUrl !== '/tinyview'">Like, Comment, and Share</h1>
              <div class="tv-card" [class.card-border]="!isSubscribePage"
                *ngIf="list.comicDescription && currentUrl !== '/tinyview'">
                <div class="series-data">
                  <div class="series-sub-block">
                    <a *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation">
                      <img [src]="seriesImage" *ngIf="seriesImage" class="series-image" alt="user's image" />
                      <p *ngIf="!seriesImage && seriesTitle">{{getFirstLetters(seriesTitle)}}</p>
                    </a>
                  </div>
                  <div class="d-flex justify-content-between flex-grow-1">
                    <a *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation" class="d-flex align-items-center">
                      <h2 *ngIf="!isCover" class="mx-2">{{seriesTitle}}</h2>
                    </a>
                    <span (click)="doFollowForComicWriters()"
                      *ngIf="!isCover && seriesTitle" class="d-flex align-items-center">
                      <div class="loader-bar-btn" *ngIf="showLoader"></div>
                      <p *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                        <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? '../../assets/images/new-icons/tick-box-100.png' : '../../assets/images/new-icons/icons8-add-100.png' }}">
                        {{followBtnText}}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="title-btn-wrapper mt-2">
                  <span class="p-bold" *ngIf="list?.comicTitle && !storyPage">
                    {{list.comicTitle}}
                  </span>
                </div>
                <!-- <p class="my-2" *ngIf="comicDate && seriesImage && !isCover">{{comicDate}}</p> -->

                <!-- <img *ngIf="list?.comments && !storyPage" src="../../assets/images/new-icons/icons8-quote-50.png" class="quotes" alt="Quotes"> -->

                <!-- <p class="comments" *ngIf="list?.comments && !storyPage"
                  [innerHTML]="list.comments | stripTags: 'br' :'p' :'i' :'b'">
                </p> -->
                <app-panel-nav *ngIf="!isCover && !storyPage && storyID && list.comicDescription"
                (doLike)="doLikefromComicPage($event)" [isLiked]="isLiked2" [commentCount]="commentCount"
                [viewCount]="pageInfo?.data?.viewCount" [likeCount]="likeCount" [storyID]="storyID"  [giftedItems]="giftedItems"
                [giftItemCount]="giftsCounts" [isBottomCard]="true" [storyData]="list"></app-panel-nav>

                <!-- COMMENTS -->
                <ng-container *ngIf="list.comicDescription && (isComicPage || currentUrl == '/tinyview/comic-series-directory') && storyID && commentCount">
                  <app-comments [showComment]="true" [anonymousUser]="anonymousUser" [record]="record || list.record"
                    [storyData]="{storyID: storyID, commentCount: commentCount, refType: refType}">
                  </app-comments>
                </ng-container>
              </div>
            </div>
            <div class="image-container" *ngIf="list?.template == templateTypes.story || (list.template == templateTypes.toc && list.actionType !== 'website' && !isSeriesPage && !isComicPage)">
              <app-comic-story [indexOfelement]='indexOfelement' [record]="record" [comicData]="list" (clicked)="fromPanelClick = true">
              </app-comic-story>
            </div>
            <div class="image-container" *ngIf="list.template == templateTypes.toc && (currentUrl != '/tinyview' && !storyPage || list.actionType === 'website')">

              <app-comic-toc [indexOfelement]='indexOfelement' [comicData]="list" (modal)="openModal(modal)"
                (clicked)="fromPanelClick = true">
              </app-comic-toc>
            </div>
            <div class="image-container" *ngIf="list.template == templateTypes.list && currentUrl != '/tinyview'">
              <app-list-view [listItemData]="list"></app-list-view>
            </div>
            <div class="image-container" *ngIf="(list.template == templateTypes.directory) && !isComicPage">
              <app-comic-directory
                [indexOfelement]='indexOfelement' [comicData]="list"
                (modal)="openModal(modal)" (clicked)="fromPanelClick = true">
              </app-comic-directory>
            </div>
            <div class="image-container" *ngIf="list.template == templateTypes.episodes && !isCover ">
              <app-comic-episodes [indexOfelement]='indexOfelement' [comicData]="list" [currentComicImage]="resp?.comics?.ogImage">
              </app-comic-episodes>
            </div>

            <div class="image-container" *ngIf="list && list.template == templateTypes.carousel && list.list && list.list.length !== 0">
              <app-scroller [panelConfigData]="list"></app-scroller>
            </div>
           </ng-container>

           <!-- comic navigation panel -->
           <ng-container *ngIf="list && list.action == 'comicNavigation' && !isComicNavLoading && (isComicPage || storyPage) && !isSubscribePage && !isNewUserPage">
            <app-comic-navigation [seriesEpisodes]="seriesEpisodes" [series]="seriesForNavigation" [action]="extActionURL" [followBtnText]="followBtnText" [hasFollowBtn]="hasFollowBtn" [hasBonusPanel]="hasBonusPanel" [currentSeriesDetails]="currentSeriesDetails" (onPropertyChange)="onBannerChange($event)"></app-comic-navigation>
           </ng-container>
           <div class="tv-card text-center" *ngIf="list && list.action == 'comicNavigation' && isComicNavLoading && (isComicPage || storyPage) && !isSubscribePage && !isNewUserPage">
            <img src="../../assets/images/new-icons/icons8-loading-v2.gif" alt="loader">
           </div>

           <!-- commented for new functionality in COMP-1028 -->
           <!-- <ng-container  *ngIf="(list && list.action == 'influencePanelForComicLimit') || (list && list.action == 'influencePanel') ">
            <div class="tv-card payment-container card-border">
                <div class="inner-block">
                  <img class="icon-32" src="../../assets/images/badge-coffee-100.png">
                  <div class="desc-block ml-3">
                    <span class="p-bold">Subscribe for the price of a cup of coffee a month!</span>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Support creators </p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Unlock Bonus pannels</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Read Unlimited Comics</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Participate in the discussion</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Get subscriber badge</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Cancel at any time</p>
                    <div class="subc-block mt-3">
                      <button (click)="router.navigate(['/tinyview/subscribe'])" class="btn tv-btn-red">
                        <p class="text-white">Subscribe</p>
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="list.action == 'influencePanelForComicLimit'">
              <div class="inner-block">
                  <img class="icon-32" src="../../assets/images/add-user-group-man-100.png" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Invite Friends
                  </div>
                  <p class="my-3">
                    <span>
                      Once you have 5 friends, you can access unlimited comics.
                    </span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="openInviteFriendsModal(inviteFriendsModal)" class="btn tv-btn-red text-center">
                      <span>Invite Friends</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="influencePoints.balance !== 0">
              <div class="inner-block">
                  <img class="icon-32" src="../../assets/images/new-icons/tinyview-influence-points-outline-100.png" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Use Influence Points
                  </div>
                  <span class="d-flex mt-2">
                    {{influencePoints.balance}}&nbsp;
                    <span><p>Current Balance</p></span>
                  </span>
                  <p class="my-3">
                    <span>
                      Don't worry - you can always earn more by sharing comics or inviting friends.
                    </span>
                    //<span *ngIf="influencePoints.balance">
                    //  Use 1 influence point to read
                    //  <span *ngIf="this.isBonusPanelComic"> the bonus panel. </span>
                    //  <span *ngIf="!this.isBonusPanelComic"> this premium comic. </span>
                    //</span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="unlockComic()" class="btn tv-btn-red text-center">
                      <p *ngIf="!isComicUnlocking" class="text-white">
                        <span>Use 1 Influence Point</span>
                      </p>
                      <div class="loader-bar-btn" *ngIf="isComicUnlocking"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="list.action !== 'influencePanelForComicLimit'">
              <div class="inner-block">
                  // <span *ngIf="influencePoints.balance"> {{influencePoints.balance}} </span> 
                  <img class="icon-32" src="../../assets/images/new-icons/tinyview-influence-points-outline-100.png" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Earn Influence Points
                  </div>
                  <span class="d-flex mt-2">
                    {{influencePoints.balance}}&nbsp;
                    <span><p>Current Balance</p></span>
                  </span>
                  <p class="my-3">
                    <span>
                      You can earn influence points by signing up, installing the app, sharing comics and adding friends.
                    </span>
                    //<span *ngIf="influencePoints.balance">
                    //  Use 1 influence point to read
                    //  <span *ngIf="this.isBonusPanelComic"> the bonus panel. </span>
                    //  <span *ngIf="!this.isBonusPanelComic"> this premium comic. </span>
                    //</span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="unlockComic('earn')" class="btn tv-btn-red text-center">
                      <p class="text-white">
                        <span> Earn Influence Points </span>
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
           </ng-container> -->

          </ng-container>
          <img class="mt-5" *ngIf="showFeedStory && !isSeriesPage" src="../../assets/images/content-placeholder.gif" />
          <span *ngIf="isSeriesPage && showFeedStory">
            <img class="mt-5" *ngIf="!comicSeriesViewTabs.list" src="../../assets/images/content-placeholder.gif" />
            <img class="mt-5" *ngIf="comicSeriesViewTabs.list" src="../../assets/images/new-icons/listview-placeholder.gif" />
          </span>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isLoadingHeader" class="loading-container">
      <img src="../../assets/images/new-icons/page-top-placeholder.gif" alt="Page loading">
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isMobileView">
  <div class="icons-border-top"
    *ngIf="(currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser') && !isIpad && fetchDone">
    <app-bottom-comic-nav [homeRoute]="homeRoute" [seriesImage]="seriesImage">
    </app-bottom-comic-nav>
  </div>
</ng-container>

<ng-container *ngIf="(errorMsg && fetchDone)">
  <app-data-not-found></app-data-not-found>
</ng-container>
<!-- <ng-container *ngIf="errorMsg">
  <app-header
    *ngIf="(currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser' || currentDevice == 'desktopBrowser') && !isIpad">
  </app-header>
</ng-container> -->
<ng-template #openInApp>
  <ng-container>
    <div class="open-app-container">
      <button type="button" class="btn-close" aria-label="Close" (click)="closeOpenInApp()">
        <img src="../../assets/images/new-icons/icons8-delete-100.png" />
      </button>
      <div class="open-app-text">
        <h6>Tinyview is better on the app</h6>
        <p>Open this in the app to get the full experience. The app is FREE.</p>
      </div>
      <div class="btn-container">
        <button *ngIf="currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser'" class="btn" aria-label="Open"
          (click)="openApp()">
          Switch to the app
        </button>
        <!-- <button *ngIf="currentDevice != 'androidBrowser' && currentDevice != 'iosBrowser'" class="btn" aria-label="Open"
          (click)="openModal(modal)">
          Send me the link
        </button> -->
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">Send Me The Link</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img src="../../assets/images/new-icons/icons8-delete-100.png" />
    </button>
  </div>
  <div class="modal-body">
    <div class="img-container">
      <img src="/assets/images/tinyview-logo-only-mark-square-rounded.png" alt="tinyview logo">
    </div>
    <div class="desc-container">
      <div class="desc-wrapper">
        <div class="title">
          Tinyview Comics
        </div>
        <div class="desc">
          You will receive a one-time text to download the app.
        </div>
      </div>
      <div class="form-container">
        <form [formGroup]="myform" (ngSubmit)="sendSMS()">
          <div class="form-group">
            <label for="phone">Phone Number:</label>
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true"
              [separateDialCode]="separateDialCode" name="phone" formControlName="phone">
            </ngx-intl-tel-input>
          </div>
          <div class="form-group">
            <button type="submit" class="btn app-btn" [disabled]="!myform.valid">
              <span *ngIf="!willShowLoader">Send Me The Link</span>
              <div class="loader-bar" *ngIf="willShowLoader"></div>
            </button>
          </div>
          <div class="sms-response" *ngIf="smsResponse != null">
            <span
              [ngClass]="{'success-response': smsResponse =='Link sent successfully.', 'error-response': smsResponse != 'Link sent successfully.' }">{{smsResponse}}</span>
          </div>
        </form>
      </div>
      <div class="footer-wrapper">
        <span>By providing your phone number, you agree to receive a one-time automated text message with a link to get
          the app. Standard messaging rates may apply.</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actionmodal>
  <app-action-popup [likedComic]="likedComic" [modalRef]="actionModalRef"></app-action-popup>
</ng-template>

<script src='scroll-frame.js'></script>

<ng-template #shareModal>
  <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="shareRef.hide()" />
  <div class="heading">
      <h2 class="share-heading">Share it with friends</h2>
      <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
      <p class="share-sub-heading" *ngIf="isSubscriber">and help creators by spreading the word</p>
  </div>
  <div class="gift-items">
      <ng-container *ngFor="let share of shareConfig">
          <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
              <img class="share-img" [src]="share.image">
              <span class="share-title">{{ share.title }}</span>
          </div>
      </ng-container>
  </div>
  <div class="btn-container">
  </div>
</ng-template>
<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="false" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>