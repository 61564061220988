<div *ngIf="panelShow && !hideFollow && comic && comicData?.action != 'in-app-purchase'" style="padding: 28px 0px 12 px 0px;" [class.pt-4]="comicData.title == 'Bibliography'">
  <ng-container
    *ngIf="(currentUrl != subscribeURL || notificationColumn) && (comicData?.template == 'bonus' || comicData?.template || comicData?.title || comicData?.description || comicData?.comments)">
    <!-- Removed mt-1 as we should not use margin bcs we have bg colors, we should use paddings (COMWEB-528) -->
    <h1 class="title" *ngIf="comicData.title && comicData.template != 'bonus'" [ngClass]="{ 'pt-3': currentUrl !== ''}"> {{ (comicData.title == 'My Comics' && showAllComics) ? comicData.titleOn :  comicData.title}} </h1>
    <h1 class="title bonus-margin" *ngIf="comicData.title && comicData.template == 'bonus'"> {{templateMsg}} </h1>
    <p class="description" *ngIf="comicData?.template == 'story' && comicData?.comments"
      [innerHTML]="comicData.comments"></p> 
    <p class="description" *ngIf="comicData?.template != 'story' && comicData?.description" [ngClass]="{ 'link': isDescriptionLink }"
      [innerHTML]="(comicData.title == 'My Comics' && showAllComics) ? comicData.descriptionOn : comicData.description"
      (click)="redirect()"></p>
      <div style="display: flex;">
        <div class="switchText" *ngIf="comicData?.switchText"
        [innerHTML]="(comicData.title == 'My Comics' && showAllComics) ? comicData.switchTextOn :  comicData.switchText"></div>
        <label class="switch" *ngIf="false">
          <input type="checkbox" (click)="showComics.emit()" [checked]="toShowAllComics()">
          <span class="slider round"></span>
        </label>
      </div>
  </ng-container>
  <ng-container *ngIf="currentUrl == subscribeURL && comicData.title == upgradeTitle">
    <h1 class="title mb-0"> Upgrade </h1>
    <div class="description">{{ comicData?.description }}</div>
  </ng-container>
  <div style="margin: 0px 20px;">
    <div *ngIf="comicData?.image && !isUpload " [ngStyle]="getImageBorder()">
      <div class="image-container">
        <a *ngIf="!isWebsite()" (click)="getImageAction()" class="w-inline-block" [class.cursor-pointer]="comicData.action"> <!-- removed pb-1 class because it was creating space between image and border when creator have specified border -->
          <img  [src]="getImgUrl(comicData)" alt="">
        </a>
        <a *ngIf="isWebsite()" target="_blank" [href]="comicAction" class="w-inline-block" alt="panel image"> <!-- removed pb-1 class because it was creating space between image and border when creator have specified border -->
          <img [src]="getImgUrl(comicData)"  [class.rounded-image]="isRounded">
        </a>
      </div>
    </div>
    <div *ngIf="comicData?.image && uploadURL" [ngStyle]="getImageBorder()">
      <div class="image-container">
        <img [src]="uploadURL" alt="panel image" >
      </div>
    </div>
    <app-comic-buttons [comicData]="comicData" [series]="series"></app-comic-buttons>
  </div>
</div>

<div *ngIf="!panelShow">
  <h1 class="ml-20 pt-1"> {{ templateMsg }} </h1>
  <app-comic-bonus [templateType]="templateType" [series]="series">
  </app-comic-bonus>
</div>
