export const environment = {
  production: true,
  env: 'development',
  API_END_POINT: 'https://storage.googleapis.com/tinyview-dev.appspot.com',
  IMAGE_END_POINT: 'https://storage.googleapis.com/tinyview-dev-images',
  CDN_BASE_URL: 'https://cdn-dev.tinyview.com',
  config : {
    apiKey: 'AIzaSyDWO4UBnpiC4_1gh6xd_ezLR9UoT8f_ZfI',
    authDomain: 'tinyview-dev.firebaseapp.com',
    databaseURL: 'https://tinyview-dev.firebaseio.com',
    projectId: 'tinyview-dev',
    storageBucket: 'tinyview-dev.appspot.com',
    messagingSenderId: '535922886764',
    appId: '1:535922886764:web:d2d367bfa314ae77037049',
    measurementId: 'G-6CML64TQ2Z'
  },
  BRANCH_KEY: 'key_test_hdJKnfdE4XXLGIkXpgPf4fbbqDfSJohY',
  SWITCH_APP_LINK: 'com.newput.tinyviewdev',
  DOMAIN_NAME: 'https://dev.tinyview.com',
  EMAIL_LINK_DATA: {
    url: 'https://tinyview.test-app.link/email-link-login',
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.newput.tinyview'
    },
    android: {
      packageName: 'com.newput.tinyviewdev',
      installApp: false
    }
  }
};
