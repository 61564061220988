import { Component, Input, OnInit } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FollowModalComponent } from '../components/follow-modal/follow-modal.component';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-comic-buttons',
  templateUrl: './comic-buttons.component.html',
  styleUrls: ['./comic-buttons.component.scss']
})
export class ComicButtonsComponent implements OnInit {
  currentUrl: string;
  currentDevice: string;
  comicAction: any;
  modalRef: any;
  @Input() series;
  @Input() comicData;
  checkActionType: boolean;
  showLoader: boolean;
  hideFollow: boolean;
  constructor(
    private readonly comicService: ComicService,
    private modalService: BsModalService,
    private readonly userService: UserService
  ) {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
  }

  ngOnInit() {
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.checkAction(this.comicData);
    if (this.comicData.action === 'follow') this.setFollowBtn(this.comicData);
  }

  getViewsForExternalLink() {
    this.comicService.viewsForExternalLink(this.comicAction);
    window.open(this.comicAction, '_blank');
  }

  openNewTab(url: string) {
    window.open("//" + url, '_blank');
  }

  public checkAction(comicData) {
    if (comicData.action === 'download-app-android' || comicData.action === 'download-app-ios' || comicData.action === 'follow') {
      this.checkActionType = true;
    } else {
      this.checkActionType = false;
    }
  }


  async setFollowBtn(comicData) {
    if (comicData.action === 'follow') {
      const userData = await this.userService.getUserDetails();
      const alerts = userData.data.data.alerts;
      const series = this.series.action;
      const normalizedSeries = series && series.replace(/^\/|\/index\.json$/g, '');
      let isFollowing = normalizedSeries && alerts.hasOwnProperty(normalizedSeries);
      if (isFollowing) {
        this.hideFollow = true
      } else {
        this.hideFollow = false
      }
    } 
  }

  public async navigateByActions() {
    if (this.comicData.action == 'follow') {
      this.showLoader = true;
      const seriesName = this.currentUrl.split('/')[1];
      const followResult = await this.comicService.addRemoveLike(`${this.series.action}`, true, false, {showToast: true, series: this.series});
      if (followResult.success) {
        window.location.reload();
        this.showLoader = false;
      }  else if (followResult.openFollowModal) {
          this.openFollowModal();
        }
    } else {
      this.comicService.navigateByActions(this.comicData)
    }
  }

  openFollowModal() {
    if (this.modalRef) this.modalRef.hide();

    const initialState = {
      tinyviewPage: false,
      isFollowingAlready: false,
      series: this.series
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
    this.showLoader = false;
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }
}
