<div *ngIf="comic" class="p-0" [ngClass]="{'tv-card': !storyPage, 'pb-3': !storyPage && !isCreatorUI && comicData.commentCount, 'pb-1-px': isCreatorUI}">
  <div class="toc-panel-wrapper">
    <div class="middle-section" *ngIf="comicData.image">
      <a *ngIf="comicData && !isWebsite() && comicData.image" (click)="getImageAction()"
        style="text-decoration: none;">
        <img *ngIf="!uploadURL" [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage}" [ngStyle]="getImageBorder()" alt="panel image" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
        <img *ngIf="uploadURL" class="story-img" [src]="uploadURL" alt="panel image">
        <div class="mx-20 mt-2" *ngIf="!storyPage">
          <app-comic-rating *ngIf="comicData.isRatingDataReady" [comic]="comicData"></app-comic-rating>
        </div>
        <h2 class="title-head mt-1 pt-2 mx-20">{{comicData.title}}</h2>
        <!-- <div class="story-bottom">
          <span> {{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br/>
          <span class="story-title">{{comicData?.title}}</span><br/>
        </div> -->
      </a>
      <a (click)="getViewsForExternalLink()" *ngIf="isWebsite()" [href]="comicAction" style="text-decoration: none;" target="_blank">
        <img *ngIf="!uploadURL" [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage}" alt="panel image" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
        <img *ngIf="uploadURL" class="story-img" [src]="uploadURL" alt="panel image">
        <div class="mx-20 mt-2" *ngIf="!storyPage">
         <app-comic-rating *ngIf="comicData.isRatingDataReady" [comic]="comicData"></app-comic-rating>
        </div>
        <div class="d-flex mt-1 pt-2 mx-20 justify-content-between" *ngIf="isWebsite() && !storyPage">
          <h2 class="title-head">{{comicData.title}}</h2>
          <img src="../../assets/images/new-icons/icons8-external-link-100.png" width="20px" height="20px" alt="External Link">
        </div>
        <!-- <div class="story-bottom">
          <span>{{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br/>
          <span class="story-title">{{comicData?.title}}</span><br/>
        </div> -->
      </a>
      <div class="d-flex mr-3" [class.mt-3]="storyPage" *ngIf="storyPage">
        <a *ngIf="comicData?.user?.image && !storyPage" (click)="onImageClick()" class="pt-3">
          <div class="user-img-wrapper">
            <img class="user-img" [src]="getImgUrl(comicData.user)" alt="user's image"
            onError="this.src='../../assets/images/male-user-100.png'">
          </div>
        </a>
        <a *ngIf="(comicData && !comicData?.user?.image) && comicData?.user?.name && !storyPage" (click)="onImageClick()" class="pt-3">
          <div class="user-img-wrapper2">
            <p>{{getFirstLetters(comicData.user.name)}}</p>
          </div>
        </a>
        <div class="user-name-wrapper" *ngIf="comicData?.image" [class.mt-3]="!storyPage">
          <p class="comments" [ngClass]="{ 'story-page-comments': storyPage }">Comments</p>
          <div class="d-inline-flex">
            <div>
              <a *ngIf="comicData?.user?.name && !isWebsite()" (click)="onImageClick()">
                <div class="user-name p-bold">{{comicData?.user.name}}
                  <span class="user-badges" *ngIf="comicData?.user?.badges && comicData?.user?.badges[0]">
                    <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comicData.user.badges[0])">
                  </span>
                </div>
              </a>
              <a *ngIf="comicData?.series && isWebsite()"[routerLink]="['/'+ comicData.series]">
                <h2 class="ml-2 pl-1">{{formatSeriesName(comicData.series)}}</h2>
              </a>
              <div class="mx-2 px-1 mt-1 mb-2" *ngIf="storyPage">
                <app-comic-rating *ngIf="comicData.isRatingDataReady" [comic]="comicData"></app-comic-rating>
              </div>
              <a *ngIf="comicData?.title && isWebsite()" (click)="openLinkInNewTab(comicAction)">
                <div class="user-name p-bold title-head">{{comicData.title}}
                  <span class="user-badges" *ngIf="comicData?.user?.badges && comicData?.user?.badges[0]">
                    <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comicData.user.badges[0])">
                  </span>
                </div>
              </a>
            </div>
          </div>
          <p class="story-comic-date" *ngIf="comicData.datetime || comicData.createdAt">{{comicService.formatDate(comicData.datetime || comicData.createdAt)}}</p>
        </div>
        <button *ngIf="storyPage && !isWebsite()" class="btn tv-btn-light series-btn body-mini story-series-btn" (click)="getImageAction()">
          View Comic
        </button>
        <button *ngIf="storyPage && isWebsite()" class="btn tv-btn-light series-btn body-mini story-series-btn" [class.no-series-name]="!comicData.series" (click)="openLinkInNewTab(comicAction)">
          {{ externalButtonText }}
        </button>
      </div>
      <div *ngIf="storyPage && (comicData.comments || comicData.comment)">
        <!-- <img src="../../assets/images/new-icons/icons8-quote-50.png" class="mt-2 quotes" alt="Quotes"> -->
        <div (click)="getCommentAction()" class="story-comments cursor-pointer comments" [innerHTML]="(comicData.comments || comicData.comment) | stripTags: 'br' :'p' :'i' :'b'"
        [ngClass]="{ 'story-page-comments': storyPage }"></div>
      </div>

      <div class="top-section m-2 mx-20" *ngIf="!storyPage">
        <div class="user-name-wrapper" *ngIf="(comicData.datetime || comicData.createdAt)">
          <p class="toc-comic-date">{{comicService.formatDate(comicData.datetime || comicData.createdAt)}}</p>
        </div>
        <div *ngIf="comicData.comments || comicData.comment">
          <!-- <img src="../../assets/images/new-icons/icons8-quote-50.png" class="mt-2 quotes ml-0" alt="Quotes"> -->
          <div (click)="getCommentAction()" class="story-comments cursor-pointer comments" [innerHTML]="(comicData.comments || comicData.comment) | stripTags: 'br' :'p' :'i' :'b'"
          [ngClass]="{ 'story-page-comments': storyPage }"></div>
        </div>
      </div>
    </div>
    <app-action-bar [viewCount]="comicData?.views" *ngIf="!hideBottomSection && !loadingStory && (comicData?.image && comicData?.template != 'stories' && !storyPage)"
      [comicAction]="comicData?.action"  [isLike]="comicData?.isLiked" [commentCount]="comicData.commentCount" [repostCount]="comicData?.repostCount"
      [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID" [giftItemCount]="comicData?.giftsCount" [storyData]="comicData"
      [hideCommentSection]="isComicPage" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
      [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
    </app-action-bar>
    <app-comic-buttons *ngIf="!hideBottomSection" [comicData]="comicData"></app-comic-buttons>
  </div>
  <app-action-bar [viewCount]="comicData?.views" *ngIf="!hideBottomSection && comicData?.image && comicData?.template != 'stories' && storyPage"
    [comicAction]="comicData?.action"  [isLike]="comicData?.isLiked" [commentCount]="comicData.commentCount" [repostCount]="comicData?.repostCount"
    [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID" [giftItemCount]="comicData?.giftsCount" [storyData]="comicData"
    [hideCommentSection]="isComicPage" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
    [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
  </app-action-bar>
</div>