import { Injectable } from "@angular/core";
import { FirebaseService } from "./firebase.service";
import { CacheService } from "./cache.service";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private firebaseService: FirebaseService,
    private cacheService: CacheService
  ) { }

  public async getAppConfig() {
    const cacheAppConfigData = this.cacheService.get('appConfigData');
    if (cacheAppConfigData) return JSON.parse(cacheAppConfigData);
    const getAppConfig = this.firebaseService.getFirebaseFunction('getAppConfig');
    const res = await getAppConfig();
    const appConfigData = res.data.data;
    this.cacheService.set('appConfigData', `${JSON.stringify(appConfigData)}`, 500)
    return appConfigData
  }

  public async updateComicDataWithConfig(comicData) {
    const appConfigData = await this.getAppConfig();
    const appConfig = appConfigData.comicConfig;
    // Set default keys if they are not present in comicData
    const ageRatingKey = comicData["rating"] || "all-ages";
    const exclusivityKey = comicData["exclusivity"] || "non-exclusive";
    const freemiumKey = comicData["show-to"] || "everyone";
    // const freemiumKey = "premium";

    // Update comicData with corresponding values from appConfig
    return {
      ...comicData,
      "rating": appConfig["rating"] && { ...appConfig["rating"][ageRatingKey], key: ageRatingKey } || null,
      "exclusivity": appConfig["exclusivity"] && { ...appConfig["exclusivity"][exclusivityKey], key: exclusivityKey } || null,
      "show-to": appConfig["show-to"] && { ...appConfig["show-to"][freemiumKey], key: freemiumKey } || null,
      "isRatingDataReady": true
    };
  }

  public async getGifts() {
    const appConfigData = await this.getAppConfig();
    const appGifts = appConfigData.giftItems;
    return appGifts;
  }
}