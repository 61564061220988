import { Component, OnInit } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { AuthService } from '../services/auth.service';
import { isAnonymousUser } from '../utilities/common.util';
import { BROWSER } from '../constants/common.constants';
import { StoryFeedService } from '../services/story-feed.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { INFLUENCE_PAGE } from '../constants/influence.constants';
import { SessionStorageService } from '../services/session-storage.service'
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
const IMAGE_END_POINT = `${environment.API_END_POINT}`;

@Component({
  selector: 'app-manage-influence',
  templateUrl: './manage-influence.component.html',
  styleUrls: ['./manage-influence.component.scss', './../comic/comic.component.scss']
})
export class ManageInfluenceComponent implements OnInit {
  param: string;
  influenceCards: { title: string; subTitle: string; image: string; btnText: string; show?: boolean; action?: string; disabled?: boolean; btnImage?: string }[];
  influenceBenefits: { title: string; description: string; image: string; show: boolean }[];
  currentDevice: string;
  currentUrl: string;
  userDetails: any;
  anonymousUser: boolean;
  showLoader: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isDesktop: boolean;
  storyID: any;
  storyAction: any;
  public INFLUENCE_PAGE = INFLUENCE_PAGE;
  isOpenApp: any = true;
  isWebsite: boolean;

  constructor(
    private comicService: ComicService,
    public authService: AuthService,
    public feedService: StoryFeedService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private sessionStorageService: SessionStorageService
  ) {
    // const metaData = [{
    //   property: 'twitter:description',
    //   content: INFLUENCE_PAGE.description
    // },
    // {
    //   property: 'twitter:image',
    //   content: INFLUENCE_PAGE.image
    // },
    // {
    //   property: 'twitter:title',
    //   content: INFLUENCE_PAGE.title
    // },
    // {
    //   property: 'og:title',
    //   content: INFLUENCE_PAGE.title
    // },
    // {
    //   property: 'og:description',
    //   content: INFLUENCE_PAGE.description
    // },
    // {
    //   property: 'og:image',
    //   content: INFLUENCE_PAGE.image
    // },
    // {
    //   property: 'og:url',
    //   content: `https://tinyview.com${INFLUENCE_PAGE.url}`
    // },
    // ];

    // for (const metaProperty of metaData) {
    //   this.meta.updateTag({
    //     property: metaProperty.property,
    //     content: metaProperty.content
    //   });
    // }
  }

  async ngOnInit() {
    this.titleService.setTitle(INFLUENCE_PAGE.title);
    this.authService.isAnonymousUser();
    this.anonymousUser = isAnonymousUser();
    this.comicService.getUserDetails().then(async (val) => {
      this.userDetails = val.data;
      this.influenceBenefits = [
        {
          title: 'Read bonus panels',
          description: 'You can use 1 influence point to unlock and read a bonus panel.',
          image: '../../assets/images/eye-100.png',
          show: true
        },
        {
          title: 'Read subscribers-only comics',
          description: 'You can use 5 influence points to unlock and read a subscribers-only comic.',
          image: `${IMAGE_END_POINT}/tinyview/app/icons8-crown-60.png`,
          show: true
        },
      ];
    });
    this.feedService.getStory(window.location.pathname + '/index.json').then(res => {
      if (res && res.data && res.data.data) {
        this.storyID = res.data.data.storyID;
        this.storyAction = res.data.data.action;
        this.isWebsite = res.data.data.actionType === 'website';
      }
    }, err => console.log(err));
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isIOS = !!(this.currentDevice == BROWSER.IOS);
    this.isAndroid = !!(this.currentDevice == BROWSER.ANDROID);
    this.isDesktop = !!(this.currentDevice == BROWSER.DESKTOP);
    this.currentUrl = this.comicService.getCurrentUrl();
    this.influenceCards = [
      {
        title: 'Sign Up',
        subTitle: 'Sign up so you can access your account from any device or Web. Earn 5 influence points each when you sign up using email and phone.',
        image: '../../assets/images/new-icons/icons8-sign-in-100.png',
        btnText: 'Sign Up',
        show: this.anonymousUser,
        action: 'signUp'
      },
      {
        title: 'Install the app',
        subTitle: 'Get alerts so you never miss a comic. Earn 5 influence points.',
        image: '../../assets/images/new-icons/icons8-app-store-100.png',
        btnText: 'Install App',
        show: this.isIOS || this.isDesktop,
        action: 'openApp',
        disabled: this.isDesktop,
        btnImage: '../../assets/images/new-icons/download-on-the-app-store.svg'
      },
      {
        title: 'Install the app',
        subTitle: 'Get alerts so you never miss a comic. Earn 5 influence points.',
        image: '../../assets/images/new-icons/icons8-google-play-100.png',
        btnText: 'Install App',
        show: this.isAndroid || this.isDesktop,
        action: 'openApp',
        disabled: this.isDesktop,
        btnImage: '../../assets/images/new-icons/google-play-badge.png'
      },
      {
        title: 'Share Comics',
        subTitle: 'Share comics with friends or on social media. You earn 1 influence point for each click.',
        image: '../../assets/images/new-icons/icons8-share-100-3.png',
        btnText: 'Share',
        show: true,
        action: 'share'
      },
      {
        title: 'Invite Friends',
        subTitle: `Know when a friend likes or comments on a comic. Earn 5 influence points for every invite accepted by friends.`,
        image: '../../assets/images/new-icons/icons8-add-user-group-man-man-100.png',
        btnText: 'Invite Friends',
        show: true,
        disabled: false,
        action: 'inviteFriends'
      }
    ];

    const user = firebase.auth().currentUser;
    this.route.queryParams.subscribe(params => {
      this.comicService.provideInfluencePoint(params);
    });

    this.comicService.getComicTitle().subscribe(res => {
      // Setting custom BG colors for each series
      const seriesName = 'tinyview';
      const color =
        (seriesName &&
          res[seriesName.replace("/", "")] &&
          res[seriesName.replace("/", "")].style &&
          res[seriesName.replace("/", "")].style.backgroundColor) ||
        "#FFE29A";
      document.documentElement.style.setProperty(`--bg-color`, color);
    });
  }

  closeOpenAppCard() {
    this.isOpenApp = false;
    this.sessionStorageService.setItem('isOpenApp', `${this.isOpenApp}`)
  }

  public openApp(): void {
    this.comicService.openInStore(this.param || '');
  }

  async checkUserComicAccess() {
    const accessAllowed = await this.comicService.checkUserComicAccess();
    if (accessAllowed.hasAllComicAccess) {
      return true;
    }
    return false;
  }
}
