<div class="tv-card card-border custom-card-style">
   <div class="d-flex justify-content-between">
     <!-- Enable navigation image buttons -->
     <button *ngIf="!disableImages.firstComic" class="nav-arrow-img btn tv-btn-light" (click)="navigateTo(imageNavigation.firstComic)">
        <img src="../../assets/images/new-icons/left-start-arrow-100.png">
    </button>

    <!-- Disabled navigation image buttons -->
    <button *ngIf="disableImages.firstComic" class="nav-arrow-img btn tv-btn-light disabled">
        <img class="disabled" src="../../assets/images/new-icons/white-left-start-arrow-100.png">
    </button>

    <!-- Enable navigation image buttons -->
    <button *ngIf="!disableImages.prevComic" class="nav-img prev-img btn tv-btn-red" (click)="navigateTo(imageNavigation.prevComic)">
        <img src="../../assets/images/new-icons/white-left-100.png">
        Prev
    </button>

    <!-- Disabled navigation image buttons -->
    <button *ngIf="disableImages.prevComic" class="nav-img prev-img btn tv-btn-red disabled">
        <img class="disabled" src="../../assets/images/new-icons/white-left-100.png">
        Prev
    </button>

    <!-- Enable navigation image buttons -->
    <button *ngIf="!disableImages.nextComic" class="nav-img next-img btn tv-btn-red" (click)="navigateTo(imageNavigation.nextComic)">
        Next
        <img src="../../assets/images/new-icons/white-right-100.png">
    </button>

    <!-- Disabled navigation image buttons -->
    <button *ngIf="disableImages.nextComic" class="nav-img next-img btn tv-btn-red disabled">
        Next
        <img class="disabled" src="../../assets/images/new-icons/white-right-100.png">
    </button>

    <!-- Enable navigation image buttons -->
    <button *ngIf="!disableImages.lastComic" class="nav-arrow-img btn tv-btn-light" (click)="navigateTo(imageNavigation.lastComic)">
        <img src="../../assets/images/new-icons/right-end-arrow-100.png">
    </button>

    <!-- Disabled navigation image buttons -->
    <button *ngIf="disableImages.lastComic" class="nav-arrow-img btn tv-btn-light disabled">
        <img class="disabled" src="../../assets/images/new-icons/white-right-end-arrow-100.png">
    </button>
   </div>
   <div>
    <app-top-banner [followBtnText]="followBtnText" [hasFollowBtn]="hasFollowBtn" [hasBonusPanel]="hasBonusPanel" [series]="currentSeriesDetails" (onPropertyChange)="onBannerChange($event)" [isTopBanner]="false" [showTo]="null"></app-top-banner>
   </div>
</div>
