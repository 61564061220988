import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComicService } from '../services/comic.service';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { isPlatformBrowser } from '@angular/common';
import { PanelService } from '../services/panel/panel.service';
import { StoryFeedService } from '../services/story-feed.service';
import { LocalStorageService } from '../services/local-storage.service';
import { Subject } from 'rxjs';
import { traceStates } from '../constants/common.constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FollowModalComponent } from '../components/follow-modal/follow-modal.component';
import { convertToTitleCase } from '../utilities/common.util';

@Component({
  selector: 'app-comic-directory',
  templateUrl: './comic-directory.component.html',
  styleUrls: ['./comic-directory.component.scss', './../comic/comic.component.scss']
})
export class ComicDirectoryComponent implements OnInit, OnChanges {
  @Input() comicData: any;
  @Input() isUpload: boolean = false;
  @Input() uploadURL: string = '';
  @Input() isRounded: any;
  @Output() modal = new EventEmitter();
  @Output() clicked = new EventEmitter();
  @Input() indexOfelement: any;
  @Input() isUserLikesLoading: boolean;
  nextRoute: string;
  currentUrl: string;
  currentDevice: string;
  routePath: string;
  query: string;
  isFollow: boolean;
  followText: string;
  viewText: string;
  likeText: string;
  pageInfo: firebase.functions.HttpsCallableResult;
  comicAction: any;
  checkActionType: boolean;
  showLoader: boolean = false;
  private readonly $userDetails: Subject<any>;
  hasAnyChannel: boolean;
  modalRef: any;
  seriesTitle: string;

  constructor(
    private readonly router: Router,
    private readonly comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    public panelService: PanelService,
    private localStorageService: LocalStorageService,
    public feedService: StoryFeedService,
    private modalService: BsModalService
  ) {
    this.$userDetails = this.comicService.$userDetails;
  }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
    this.viewText = this.comicData.viewCount == 1 ? 'View' : 'Views';
    this.likeText = this.comicData.likeCount == 1 ? 'Follower' : 'Followers';
    this.followText = 'Follow';
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.checkActionType = this.checkAction(this.comicData);
    this.nextRoute = this.getNavigateUrl();
    if (this.nextRoute && this.nextRoute.indexOf('fn=') != -1) {
      this.query = this.nextRoute.slice(this.nextRoute.indexOf('fn=') + 3, this.nextRoute.length);
      this.routePath = this.nextRoute.slice(0, this.nextRoute.indexOf('?'));
    }

    this.$userDetails.subscribe(async (val) => {
      if (Object.keys(val.data.alerts || {}).length) {
        const followedComics = Object.keys(val.data.alerts).filter(key => val.data.alerts[key] === true);;
        this.isFollow = !!followedComics.find(series => this.comicData.action.split('/')[1].indexOf(series) > -1);
        this.followText = this.isFollow ? 'Following' : 'Follow';
        this.currentUrl = this.comicService.getCurrentUrl();
        if (this.currentUrl.indexOf('/tinyview/comic-series-directory') > -1) { // for directory page handling
          if (![traceStates.STATE_UNSPECIFIED, traceStates.COMPLETED].includes(this.comicService.pageLoadTrace && this.comicService.pageLoadTrace.state)) {
            this.comicService.pageLoadTrace.stop();
          }
        }
      }
      this.hasAnyChannel = this.comicService.userHasAnyChannel(val);
    })
  }

  async ngOnChanges() {
    await new Promise(resolve => setTimeout(resolve, 2500));
  }
  public getViewsForExternalLink() {
    this.comicService.viewsForExternalLink(this.comicAction);
  }


  openFollowModal(comicData) {
    if (this.modalRef) this.modalRef.hide();
    
    const series = {
      title: comicData.title,
      action: comicData.action
    }
    const initialState = {
      tinyviewPage: false,
      isFollowingAlready: this.isFollow,
      series
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
    this.showLoader = false;
  }

  public async getAddRemoveLikeData(comicData) {
    this.showLoader = true;
    this.comicService.cacheComic[""] = null;

    const isFollowAction = !this.isFollow;
    const likeUrl = comicData.action;
    const series = {
      action: comicData.action,
      title: comicData.title
    }

    const followResult = await this.comicService.addRemoveLike(likeUrl, isFollowAction, false, {showToast: true, series: series});
    if (followResult.success) {
      if (isFollowAction) {
        this.comicData.likeCount += 1;
        this.followText = 'Following';
        this.isFollow = true;
      } else if (!isFollowAction) {
        this.comicData.likeCount -= 1;
        this.followText = 'Follow';
        this.isFollow = false;
      }
      this.likeText = this.comicData.likeCount === 1 ? 'Follower' : 'Followers';
      this.showLoader = false;
    } else if (followResult.openFollowModal) {
      this.openFollowModal(comicData);
    }
  }

  public checkAction(comicData) {
    if (comicData.action == 'download-app-android' || comicData.action == 'download-app-ios' || comicData.action == 'share' || comicData.action == 'restore-purchases') {
      return true;
    } else {
      return false;
    }
  }

  public getImageAction() {
    if (this.checkActionType && !this.comicData.button) {
      this.navigateByActions()
    } else if (!this.isWebsite()) {
      this.onImageClick()
    }
  }

  public navigateByActions() {
    this.comicService.navigateByActions(this.comicData);
  }

  public isTinyView(): boolean {
    return this.comicData.actionType === 'tinyview';
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image, this.currentUrl);
  }

  public getNavigateUrl(): string {
    return this.comicService.getNavigateUrl(this.comicData);
  }

  public openApp(): void {
    this.comicService.openInStore();
  }

  // public getPanelBorder(): Object {
  //   return this.comicService.getPanelBorder(this.comicData);
  // }

  public getImageBorder(): Object {
    return this.comicService.getImageBorder(this.comicData);
  }

  onManageAlertClick() {
    this.modalRef.hide();
    this.router.navigate(['manage-alerts']);
  }

  public onImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    let route = this.nextRoute.indexOf('fn=') != -1 ? this.routePath : this.nextRoute;
    if (this.query) {
      this.router.navigate([route], { queryParams: { fn: this.query }});
    } else {
      this.router.navigate([route]);
    }
    this.clicked.emit();
  }
}
